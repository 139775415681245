import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import PropTypes from 'prop-types';
import {Row} from "react-bootstrap";
import {Select} from "./style.jsx";
import {InputGroup, InputCol, Label, Wrapper, InputError} from "../InputBox/style";


class SearchDropdown extends Component {
    refInput = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            data: props.data,
            search: false
        }
    }
    componentDidMount = () => {
        const {applicationContext} = this.props;
        let searchBox = document.getElementsByClassName("bs-box bs-searchbox");
        if(searchBox.length > 0){
            for (let search of searchBox) {
                let input = search.getElementsByTagName("input");
                input[0].placeholder = applicationContext.translator("Search")
            }
        }
        if(this.props.data && this.props.data.length > 0){
            this.setState({
                search: true
            })
        }
    }
    clear = () => {
        this.setState({
            data: [],
            search: false
        })
    }
    put = (data) => {
        this.setState({
            data
        }, () => {
            this.setState({
                search: true
            })
        })
    }

    render() {
        const {applicationContext, onChange, name, data, label, line, margin, floatLabel, touched, errors, value} = this.props;
        return (
            <React.Fragment>
                <InputGroup as={Row} margin={margin} floatLabel={floatLabel}>
                    <InputCol>
                        <Wrapper line={line}>
                            {label && <Label floatLabel={floatLabel}>{label} {line?":":""}</Label>}
                            <Select
                                invalid={touched && touched[name] && (errors[name] !== undefined)}
                                name={name}
                                onChange={onChange}
                                dir={applicationContext.translator("direction")}
                                showSearch={this.state.search}
                                options={this.state.data}
                                menuSize={this.state.data.length > 7?7:this.state.data.length}
                                placeholder={applicationContext.translator("Please choose...")}

                            />
                        </Wrapper>
                        {touched && touched[name] && errors[name] && <InputError hidden={errors[name] === undefined && true}>{errors[name]}</InputError>}

                    </InputCol>
                </InputGroup>
            </React.Fragment>

        );
    }
}
SearchDropdown.propTypes = {
    margin: PropTypes.string,
    invalid: PropTypes.bool,
    padding: PropTypes.string,
    fullWidth: PropTypes.bool,
    size: PropTypes.number,
    fontSize: PropTypes.string,
    line: PropTypes.bool,
    floatLabel: PropTypes.bool,
    label: PropTypes.string,
    data: PropTypes.array,
    search: PropTypes.bool,
    onChange: PropTypes.func
}
export default withApplicationContext(SearchDropdown);