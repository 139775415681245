import styled from "styled-components";
import {Form} from 'react-bootstrap';

export const OverviewContainer = styled.div`
    width: 100%;
    max-width: 500px;
    margin: 4em auto;
  @media screen and (max-width: 700px) {
    display: block;
    margin: 0em auto;
  }
`
export const OverviewRow = styled.div`
    display: flex;
  width: 100%;
  margin: 0.3em 0;
  @media screen and (max-width: 700px) {
    display: block;
    margin: .5em 0;
  }
`;

export const OverviewTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  font-weight: 600;
  background: var(--color-overview-table-title-background);
  border-radius: 8px;
  margin-right: -1em;
  @media screen and (min-width: 701px) {
    min-width: 200px;
    max-width: 200px;
    padding: 1em;
  }
  
  @media screen and (max-width: 700px) {
    display: block;
    width: 100%;
    padding: .8em;
    text-align: center;
  }
`;
export const Sign = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 70px;
  background: var(--color-overview-table-sign-background);
  color: var(--color-text);
  border-radius: 8px;
`
export const OverviewValue = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-wrap: break-word;
  padding: 0 1em;
  width: 100%;
  text-align: right;
  background: var(--color-overview-table-value-background);
  color: var(--color-primary);
  border-radius: 8px;
  & a{
    color: var(--color-primary);
    text-decoration: underline;
    transition: all ease-in-out .3s;
    &:hover{
      color: var(--color-text);
    }
  }
  @media screen and (max-width: 700px) {
    display: block;
    width: 100%;
    padding: .5em 0;
    text-align: center;
    font-size: 1em;
    margin-top: -.4em;
  }
`;