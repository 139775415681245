import api from "./adminApi";
import {queryStringSerialize} from "../helpers";
import axios from "axios";
import AdminTokenService from "./AdminTokenService";

const getUserBoard = () => {
    return api.get("/account/profile/me").then((response) => {
        AdminTokenService.setUser(response.data);
        return AdminTokenService.getUser();
    });
};

const updateMusicVideo = (id, data) => {
    return api.post("/admin/mv/update/"+id, data).then((response) => {
        return response.data;
    });
};

const addMusicVideo = (data) => {
    return api.put("/admin/mv", data).then((response) => {
        return response.data;
    });
};


const checkMasterPassword = (data) => {
    return api.get("/admin/check_master_password?" + queryStringSerialize(data)).then((response) => {
        return response.data;
    });
};

const musicVideos = (data) => {
    return api
        .get("/admin/mv/list?"+ queryStringSerialize(data))
        .then((response) => {
            return response.data;
        });
};
const musicVideo = (id) => {
    return api
        .get("/admin/mv/"+ id)
        .then((response) => {
            return response.data;
        });
};

const users = (data) => {
    return api
        .get("/admin/users/list?"+ queryStringSerialize(data))
        .then((response) => {
            return response.data;
        });
};

const deactivate = (id) => {
    return api
        .get("/admin/users/deactivate/"+ id)
        .then((response) => {
            return response.data;
        });
};

const activate = (id) => {
    return api
        .get("/admin/users/activate/"+ id)
        .then((response) => {
            return response.data;
        });
};

const topListUserVotes = (data) => {
    return api
        .get("/admin/top20/votes/list?"+ queryStringSerialize(data))
        .then((response) => {
            return response.data;
        });
};

const availableRank = (id) => {
    return api
        .get(`/admin/top20/${id}/ranks`)
        .then((response) => {
            return response.data;
        });
};
const topListRounds = (data) => {
    return api
        .get("/admin/top20/round/list?"+ queryStringSerialize(data))
        .then((response) => {
            return response.data;
        });
};
const showTopList = (id) => {
    return api
        .get("/admin/top_list/show/"+ id)
        .then((response) => {
            return response.data;
        });
};
const counters = () => {
    return api
        .get(`/admin/reports/counters`)
        .then((response) => {
            return response.data;
        });
};

const overview = () => {
    return api
        .get(`/admin/reports/overview`)
        .then((response) => {
            return response.data;
        });
};

const totalOverview = (data) => {
    return api
        .get(`/admin/reports/overview/total?`+queryStringSerialize(data))
        .then((response) => {
            return response.data;
        });
};
const totalDeposits = (data) => {
    return api
        .get(`/admin/reports/deposits/total?`+queryStringSerialize(data))
        .then((response) => {
            return response.data;
        });
};
const ranking = (data) => {
    return api
        .get(`/admin/reports/ranking?`+queryStringSerialize(data))
        .then((response) => {
            return response.data;
        });
};
const pushRankOfMusicVideo = (id, data) => {
    return api
        .put(`/admin/top20/${id}/push`, data)
        .then((response) => {
            return response.data;
        });
};

const deleteMusicVideo = (id) => {
    return api
        .delete(`/admin/mv/${id}`)
        .then((response) => {
            return response.data;
        });
};

const AdminService = {
    getUserBoard,
    updateMusicVideo,
    deleteMusicVideo,
    addMusicVideo,
    musicVideos,
    musicVideo,
    topListRounds,
    showTopList,
    users,
    activate,
    deactivate,
    topListUserVotes,
    availableRank,
    counters,
    overview,
    totalOverview,
    totalDeposits,
    ranking,
    checkMasterPassword,
    pushRankOfMusicVideo
};

export default AdminService;