import React, {Component} from 'react';
import Footer from "../components/ui/Footer/Footer";
import {withApplicationContext} from "../contexts/ApplicationContext";
import {AccountProvider} from "../contexts/AccountContext";
import Loading from "../components/ui/Loading";
import StorageService from "../services/StorageService";
import AccountService from "../services/AccountService";
import TokenService from "../services/TokenService";
import AppHeader from "../components/ui/AppHeader";
import withRouter from "../contexts/withRouter";
import AuthService from "../services/AuthService";

class BaseMaster extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loggedIn: false,
            userInfo: {},
            popup: false,
            votingList: [],
            isVoted: false
        }

    }

    doChangeLanguage = async () => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        return "en";
        if (urlSearchParams.has('locale') && ['fa', 'en'].includes(urlSearchParams.get('locale'))) {
            StorageService.set('locale', urlSearchParams.get('locale'));
        }
        if (StorageService.get('locale')) {
            return StorageService.get('locale');
        }
        return process.env.REACT_APP_DEFAULT_LOCALE;
    }

    componentDidMount = async () => {
       const {applicationContext} = this.props;
        let userInfo = await this.fetchUserInfo();
        let locale = await this.doChangeLanguage();
        let votingList = [];
        let isVoted = false;
        await AccountService.showVote(applicationContext.state.topListId).then((data) => {
            if(data['created_at']) {
                votingList = data.music_videos;
                isVoted = true;
            }
        }).catch((e) => {

        })
        applicationContext.doUpdateLocale(locale, () => {
            this.setState({
                userInfo,
                votingList,
                isVoted,
                loggedIn: (userInfo)?true:false,
                loading: false
            })
        })

    }

    handleAutoLogin = async () => {
        const {location, navigate} = this.props;
        const params = new URLSearchParams(location.search);
        if(params.has('token')){
            let token = JSON.parse(atob(decodeURIComponent(params.get('token'))));
            if(token.hasOwnProperty('access_token')){
                await AuthService.profile(token['access_token']).then((data) => {
                    TokenService.updateLocalAccessToken(token);
                    navigate(location.pathname);
                }).catch((e) => {
                    navigate(location.pathname);
                });
            }

        }

    }

    fetchUserInfo = async () => {
        await this.handleAutoLogin();
        let userInfo = null;
        if(StorageService.get('token')){
            userInfo = await AccountService.getUserBoard().then((data) => {
                return data;
            })
        }
        return userInfo;
    }
    updateUserInfo = (data, callback, loggedIn = true) => {
        this.setState({
            loggedIn: loggedIn,
            userInfo: data
        }, callback)
    }

    handleLogout = async (callback) => {
        TokenService.removeUser();
        this.setState({
            loggedIn: false,
            userInfo: {},
        }, callback)
    }
    handleCheckExistsId = (id) => {
        let {votingList} = this.state;
        let exists = votingList.filter((row) => row.id === id);
        return exists.length > 0
    }
    onDeleteMusicFromVoteList = (id) => {
        let {votingList} = this.state;
        if(this.state.isVoted){
            this.setState({
                popup: true
            })
            return;
        }
        votingList.map((row, index) => {
            if(row.id === id)
                votingList.splice(index, 1);

        })
        this.setState({
            votingList
        })
    }
    handleClearList = () => {
        this.setState({
            votingList: []
        })
    }
    onOpenPopup = (id = null, data = null) => {
        let {votingList} = this.state;
        if(this.state.isVoted){
            this.setState({
                popup: true
            })
            return;
        }
        if(data && !this.handleCheckExistsId(id) && votingList.length < 10)
            votingList.push(data);

        this.setState({
            popup: true,
            votingList
        })
    }
    onClosePopup = () => {
        this.setState({
            popup: false
        })
    }
    onUpdateState = (state, callback = () => {}) => {
        this.setState(state, callback)
    }

    render() {
        const {children, disableTheme} = this.props;
        return (
            <AccountProvider value={{
                state: this.state,
                updateUserInfo: this.updateUserInfo,
                handleLogout: this.handleLogout,
                onDeleteMusicFromVoteList: this.onDeleteMusicFromVoteList,
                handleCheckExistsId: this.handleCheckExistsId,
                handleClearList: this.handleClearList,
                onOpenPopup: this.onOpenPopup,
                onClosePopup: this.onClosePopup,
                onUpdateState: this.onUpdateState
            }}>
                {this.state.loading?(
                    <Loading animation={true} />
                ):(
                    <React.Fragment>
                        {!disableTheme && <AppHeader />}
                        {children}
                        {!disableTheme && <Footer />}
                    </React.Fragment>
                )}
            </AccountProvider>
        );
    }
}

export default withApplicationContext(withRouter(BaseMaster));