import React, {Component} from 'react';
import {InputContainer, InputCol, InputGroup, ShowPassword, Label, InputError, Wrapper,
    ChangeButton, Postfix, Strength, StrengthBar, StrengthMessage, StrengthBarValue, PasswordRules, PasswordRule} from "./style.jsx";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import PropTypes from 'prop-types';
import {Form, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {convertToEnglishNumbers, isNumber, passwordCheck} from "../../../helpers";
const englishInput = ["email", "username", "password", "mobile", "msisdn", "national_id", "hash_code", "password_confirmation"];
const forceEnglishInput = ["mobile", "national_id", "hash_code", "msisdn", "password", "password_confirmation"];
class InputBox extends Component {
    refInput = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            showPassword: false,
            password: {
                value: "",
                progress: "",
                message: "",
                color: "",
                strengthChecks: {
                    length: 0,
                    hasUpperCase: false,
                    hasLowerCase: false,
                    hasDigit: false,
                    hasSpecialChar: false,
                }
            }
        }
    }
    componentDidMount() {
        this.handleCallbackStrength();
    }
    handleCallbackStrength = () => {
        const {applicationContext, onCallbackStrength} = this.props;
        if(onCallbackStrength){
            onCallbackStrength(
                <PasswordRules>
                    <PasswordRule check={this.state.password.strengthChecks.length}><FontAwesomeIcon icon={this.state.password.strengthChecks.length?"check":"circle"} /> {applicationContext.translator("Minimum 8 characters")}</PasswordRule>
                    <PasswordRule check={this.state.password.strengthChecks.hasUpperCase}><FontAwesomeIcon icon={this.state.password.strengthChecks.hasUpperCase?"check":"circle"} /> {applicationContext.translator("Minimum 1 uppercase")}</PasswordRule>
                    <PasswordRule check={this.state.password.strengthChecks.hasLowerCase}><FontAwesomeIcon icon={this.state.password.strengthChecks.hasLowerCase?"check":"circle"} /> {applicationContext.translator("Minimum 1 lowercase")}</PasswordRule>
                    <PasswordRule check={this.state.password.strengthChecks.hasDigit}><FontAwesomeIcon icon={this.state.password.strengthChecks.hasDigit?"check":"circle"} /> {applicationContext.translator("Minimum 1 number")}</PasswordRule>
                    <PasswordRule check={this.state.password.strengthChecks.hasSpecialChar}><FontAwesomeIcon icon={this.state.password.strengthChecks.hasSpecialChar?"check":"circle"} /> {applicationContext.translator("Minimum 1 symbol")}</PasswordRule>
                </PasswordRules>
            )
        }
    }
    onShowPassword = (e) => {
        this.setState({
            showPassword: !this.state.showPassword
        })
    }
    onKeyPress = (e) => {
        const {onKeyPress, onlyNumber} = this.props;
        if(onlyNumber && !isNumber(e))
            e.preventDefault();

        if(onKeyPress)
            return onKeyPress(e);
    }
    onKeyUp = (e) => {
        const {onKeyUp} = this.props;
        if(onKeyUp)
            return onKeyUp(e);
    }
    onChange = (e) => {
        const {applicationContext, name, onChange, strength, onCallbackStrength} = this.props;
        if(strength) {
            this.handlePasswordStrength(e.target.value);
        }
        e.target.value = convertToEnglishNumbers(e.target.value);
        if(onChange)
            return onChange(e);
    }
    onFocus = (e) => {
        const {onFocus, focus, save, readonly, name, applicationContext} = this.props;
        if(readonly)
            return onFocus(e);
        if(focus)
            e.target.select()
        if(save && save === "off")
            this.refInput.removeAttribute('readonly');
        if(applicationContext.translator("direction") === "rtl"){
            if(englishInput.includes(name)){
                this.refInput.classList.add("enInput");
            }
        }
        if(onFocus)
            return onFocus(e);
    }
    onBlur = (e) => {
        const {onBlur, applicationContext, name} = this.props;
        if(applicationContext.translator("direction") === "rtl"){
             if(englishInput.includes(name) && (this.refInput.value === "" || this.refInput.value === undefined)){
                this.refInput.classList.remove("enInput");
            }
        }
        if(onBlur)
            return onBlur(e);
    }
    value = () => {
        return this.refInput.value;
    }
    select = () => {
        this.refInput.select();
    }
    handlePasswordStrength = (passwordValue) => {
        const {applicationContext} = this.props;
        this.setState({
            password: passwordCheck(passwordValue, applicationContext)
        }, this.handleCallbackStrength)
    };
    render() {
        const {applicationContext, type, name, button, buttonLabel, readonly, buttonAlign, save, label, strength,
            postfixAlign, postfix, line, margin, floatLabel, invalid, touched, errors, dark, invalidMessage} = this.props;
        let inputProps = Object.assign({}, this.props);
        Object.keys(inputProps).forEach((prop) => {
            if(typeof inputProps[prop] === "object")
                delete inputProps[prop];
        })
        return (
            <React.Fragment>
                <InputGroup as={Row} margin={margin} floatLabel={floatLabel}>
                    <InputCol>
                        <Wrapper line={line}>
                            {label && <Label floatLabel={floatLabel}>{label} {line?":":""}</Label>}
                            <InputContainer
                                {...inputProps}
                                type={this.state.showPassword?"text":type}
                                isInvalid={invalid?invalid:(!strength && (touched && touched[name] && errors[name]))}
                                readOnly={readonly?readonly:(save && save === "off" && true)}
                                ref={(ref) => this.refInput = ref}
                                autoComplete={save && save === "off" && "off"}
                                className={englishInput.includes(name) && (this.refInput.value !== "" && this.refInput.value !== undefined)?"enInput":null}
                                onFocus={this.onFocus}
                                onBlur={this.onBlur}
                                onChange={this.onChange}
                                onKeyUp={this.onKeyUp}
                                onKeyPress={this.onKeyPress}
                                buttonAlign={buttonAlign}
                                strength={strength}
                                strengthColor={this.state.password.color}
                                dir={applicationContext.translator("direction")}
                                dark={dark}
                            />
                            {strength &&
                            <Strength>
                                <StrengthBar color={this.state.password.color}>
                                    <StrengthBarValue value={this.state.password.progress} color={this.state.password.color}/>
                                </StrengthBar>
                                {this.state.password.message && <StrengthMessage color={this.state.password.color}>
                                    {applicationContext.translator("Your password is ${value}").replaceAll("${value}", applicationContext.translator(this.state.password.message))}
                                </StrengthMessage>}
                            </Strength>
                            }
                            {type === "password" &&
                            <ShowPassword label={label !== null && label !== undefined && !line} dir={applicationContext.translator("direction")} onClick={this.onShowPassword}>
                                <FontAwesomeIcon icon={this.state.showPassword?"eye-slash":"eye"} />
                            </ShowPassword>
                            }
                            {button &&
                            <ChangeButton align={buttonAlign} label={label !== null && label !== undefined && !line} onClick={button}>
                                {buttonLabel??"Change"}
                            </ChangeButton>
                            }
                            {postfix &&
                            <Postfix invalid={invalid?invalid:(touched && touched[name] && errors[name])} align={postfixAlign} label={label !== null && label !== undefined && !line}>
                                {postfix}
                            </Postfix>
                            }
                        </Wrapper>
                        {touched && touched[name] && <InputError line={line} hidden={errors[name] === undefined && true}>{errors[name]}</InputError>}
                        {invalidMessage && <InputError line={line}>{invalidMessage}</InputError>}

                    </InputCol>
                </InputGroup>

            </React.Fragment>

        );
    }
}
InputBox.propTypes = {
    type: PropTypes.string,
    margin: PropTypes.string,
    invalid: PropTypes.bool,
    invalidMessage: PropTypes.string,
    padding: PropTypes.string,
    fullWidth: PropTypes.bool,
    size: PropTypes.number,
    onlyNumber: PropTypes.bool,
    center: PropTypes.bool,
    fontSize: PropTypes.string,
    line: PropTypes.bool,
    floatLabel: PropTypes.bool,
    button: PropTypes.func,
    buttonLabel: PropTypes.any,
    buttonAlign: PropTypes.string,
    readonly: PropTypes.bool,
    postfix: PropTypes.any,
    postfixAlign: PropTypes.bool,
    strength: PropTypes.bool,
    dark: PropTypes.bool
}
export default (withApplicationContext(InputBox));