import React, {Component} from 'react';
import {Route, Routes} from "react-router-dom";
import {withApplicationContext} from "../contexts/ApplicationContext"
import {
    ROUTE_ADMIN_ADD_MUSIC_VIDEOS,
    ROUTE_ADMIN_LOGIN,
    ROUTE_ADMIN_MUSIC_VIDEOS,
    ROUTE_ADMIN_OVERVIEW,
    ROUTE_ADMIN_PLAYERS,
    ROUTE_ADMIN_SEARCH,
    ROUTE_ADMIN_SEND_EMAILS, ROUTE_ADMIN_TOP_2023_MEMBERS,
    ROUTE_ADMIN_TOP_20_LIST,
    ROUTE_ADMIN_TOP_20_MEMBERS,
    ROUTE_ADMIN_TOP_20_VOTES_LIST, ROUTE_ADMIN_TOP_80_MEMBERS,
    ROUTE_ADMIN_UPDATE_MUSIC_VIDEOS
} from "./routes";
import Login from "../scenes/Admin/Login";
import BaseMaster from "../scenes/Admin/BaseMaster";
import Overview from "../scenes/Admin/Overview";
import Users from "../scenes/Admin/Users";
import Search from "../scenes/Admin/Search";
import SendEmail from "../scenes/Admin/SendEmail";
import MusicVideo from "../scenes/Admin/MusicVideo";
import AddMusicVideo from "../scenes/Admin/MusicVideo/AddMusicVideo";
import Top20 from "../scenes/Admin/Top20";
import Top20Votes from "../scenes/Admin/Top20Votes";
import MembersTop20 from "../scenes/Admin/MembersTop20";
import MembersTop80 from "../scenes/Admin/MembersTop80";
import MembersTop2023 from "../scenes/Admin/MembersTop2023";
import PreviousTop20 from "../scenes/Admin/PreviousTop20";

class AdminRouter extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        return (
            <Routes>
                <Route path={ROUTE_ADMIN_LOGIN} element={<BaseMaster><Login /></BaseMaster>} />
                <Route path={ROUTE_ADMIN_OVERVIEW} element={<BaseMaster><Overview /></BaseMaster>} />
                <Route path={ROUTE_ADMIN_PLAYERS} element={<BaseMaster><Users /></BaseMaster>} />
                <Route path={ROUTE_ADMIN_MUSIC_VIDEOS} element={<BaseMaster><MusicVideo /></BaseMaster>} />
                <Route path={ROUTE_ADMIN_ADD_MUSIC_VIDEOS} element={<BaseMaster><AddMusicVideo /></BaseMaster>} />
                <Route path={ROUTE_ADMIN_UPDATE_MUSIC_VIDEOS} element={<BaseMaster><AddMusicVideo /></BaseMaster>} />

                <Route path={ROUTE_ADMIN_TOP_20_LIST} element={<BaseMaster><PreviousTop20 /></BaseMaster>} />
                <Route path={ROUTE_ADMIN_TOP_20_VOTES_LIST} element={<BaseMaster><Top20Votes /></BaseMaster>} />
                <Route path={ROUTE_ADMIN_TOP_20_MEMBERS} element={<BaseMaster><MembersTop20 /></BaseMaster>} />
                <Route path={ROUTE_ADMIN_TOP_80_MEMBERS} element={<BaseMaster><MembersTop80 /></BaseMaster>} />
                <Route path={ROUTE_ADMIN_TOP_2023_MEMBERS} element={<BaseMaster><MembersTop2023 /></BaseMaster>} />


                <Route path={ROUTE_ADMIN_SEARCH} element={<BaseMaster><Search /></BaseMaster>} />
                <Route path={ROUTE_ADMIN_SEND_EMAILS} element={<BaseMaster><SendEmail /></BaseMaster>} />


            </Routes>
        );
    }
}

export default withApplicationContext((AdminRouter));