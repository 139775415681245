import React, {Component} from 'react';
import {withApplicationContext} from "../contexts/ApplicationContext"
import {AppContainer} from "../components/ui/AppStyle";
import AdminRouter from "./AdminRouter";
import Popup from "../components/ui/Popup";
import FrontendRouters from "./FrontendRouters";
class Routers extends Component {
    render() {
        const {applicationContext} = this.props;
        return (
            <React.Fragment>
                <AppContainer direction={applicationContext.translator("direction")}>
                    <FrontendRouters />
                    <AdminRouter />
                    {applicationContext.state.popup.show &&
                    <Popup size={applicationContext.state.popup.size}
                           title={applicationContext.state.popup.title}
                           onClose={applicationContext.onClosePopup}
                    >
                        {applicationContext.state.popup.component}
                    </Popup>
                    }
                    {applicationContext.state.confirmPopup.show &&
                    <Popup size={applicationContext.state.confirmPopup.size}
                           title={applicationContext.state.confirmPopup.title}
                           footer={applicationContext.state.confirmPopup.footer}
                           onClose={applicationContext.state.confirmPopup.onClose}
                           submitting={applicationContext.state.confirmPopup.submitting}
                    >
                        {applicationContext.state.confirmPopup.component}
                    </Popup>
                    }
                </AppContainer>
            </React.Fragment>
        );
    }
}

export default withApplicationContext((Routers));