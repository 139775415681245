import React, {Component} from 'react';
import {AddButton, ButtonContainer, SearchButton, SearchItem, SearchWrapper} from "./style.jsx";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import PropTypes from 'prop-types';
import SearchDropdown from "../SearchDropdown/SearchDropdown";
import InputBox from "../InputBox/InputBox";
import Button from "../Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Card from "../Card";
import {condition, yearRange} from "../../../helpers";
import Dropdown from "../Dropdown/Dropdown";
import DatePicker from "../DatePicker";
class SearchTable extends Component {
    refSearchDropDown = [];
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            submitting: false,
            clearSubmitting: false,
            searchCompleted: false,
            defaultSearch: {},
            search: {},
            sort: {}
        }
    }
    componentDidMount() {
        const {sort, search} = this.props
        let sortField = (sort && sort.hasOwnProperty('field'))?sort.field:'id';
        let sortDir = (sort && sort.hasOwnProperty('dir'))?sort.dir:'asc';
        let searchValues = {};
        search.map((item) => {
            searchValues[item.id] = item.hasOwnProperty('defaultValue')?item.defaultValue:"";
        })
        this.setState({
            loading: false,
            search: searchValues,
            defaultSearch: searchValues,
            sort: {
                field: sortField,
                dir: sortDir
            }
        })
    }

    onSearch = async (e) => {
        const {doFetchData} = this.props;
        this.setState({ submitting: true });
        await doFetchData(1, this.state.sort.field, this.state.sort.dir, this.state.search, () => {
            this.setState({
                submitting: false,
                searchCompleted: true
            });
        })
    }
    onClearSearch = async (e) => {
        const {doFetchData, search} = this.props;
        Object.keys(this.refSearchDropDown).map((dropdown) => {
            this.refSearchDropDown[dropdown].clear();
        })

        this.setState({
            clearSubmitting: true,
            search: this.state.defaultSearch
        }, async () => {
            await doFetchData(1, this.state.sort.field, this.state.sort.dir, this.state.search, () => {
                Object.keys(this.refSearchDropDown).map((dropdown) => {
                    let obj = search.searchById(dropdown);
                    if(obj)
                        this.refSearchDropDown[dropdown].put(obj.data[obj.parse]());

                })
                this.setState({
                    clearSubmitting: false,
                    searchCompleted: false
                });
            })
        });

    }
    onChange = (field, value) => {
        this.setState({
            search: {
                ...this.state.search,
                [field]: value
            }
        })
    }
    checkSearchFields = () => {
        return this.state.search.isEmptyArray();
    }
    renderInput = (item) => {
        const {applicationContext} = this.props;
        if(!item.hasOwnProperty('type')) return null;
        if(item.hasOwnProperty('hidden') && condition(this.state.search, item.hidden)){
            return null;
        }
        switch (item.type){
            case "textbox":
                return (
                    <SearchItem>
                        <InputBox type="text"
                                  name={item.id}
                                  onChange={(e) => this.onChange(item.id, e.target.value)}
                                  margin={"0"}
                                  onlyNumber={item.hasOwnProperty('onlyNumber') && item.onlyNumber}
                                  value={this.state.search[item.id]}
                                  placeholder={item.hasOwnProperty('label') && applicationContext.translator(item.label)}
                        />
                    </SearchItem>
                )
                break;
            case "dropdown":
                return (
                    <SearchItem>
                        <Dropdown
                            name={item.id}
                            onChange={(e) => this.onChange(item.id, e.target.value)}
                            value={this.state.search[item.id]}
                            margin={"0"}
                        >
                            {!item.hasOwnProperty('disableEmpty') && <option value={""}>{item.hasOwnProperty('defaultLabel')?applicationContext.translator(item.defaultLabel):applicationContext.translator("Please choose...")}</option>}
                            {item.data.map((row, index) => {
                                return <option key={index} value={row.key}>{applicationContext.translator(row.label)}</option>
                            })}
                        </Dropdown>
                    </SearchItem>
                )
                break;
            case "dropdown_searchable":
                return (
                    <SearchItem>
                        <SearchDropdown
                            name={item.id}
                            onChange={(e) => e.selectedKey[0] !== undefined && this.onChange(item.id, e.selectedKey[0]) }
                            margin={"0"}
                            data={item.data[item.parse]()}
                            ref={(ref) => this.refSearchDropDown[item.id] = ref}
                        />
                    </SearchItem>
                )
                break;
            case "datepicker":
                return (
                    <SearchItem>
                        <DatePicker
                            placeholder={item.label}
                            format={'YYYY-MM-DD'}
                            onChange={(date, formatDate) => this.onChange(item.id, formatDate)}
                            value={this.state.search[item.id]}
                            blackoutDates={item.hasOwnProperty('blackoutDates') && item.blackoutDates}
                            enableDays={item.hasOwnProperty('enableDays') && item.enableDays}
                            searchOnChange={item.hasOwnProperty('onChange') && this.props.doFetchData}
                        />
                    </SearchItem>
                )
                break;
        }
        return null;
    }
    render() {
        const {loading, search, children, addButton, doAddButton} = this.props;

        return (
            <React.Fragment>
                <Card padding={"1em"} margin={"0 0 1em 0"} loading={this.state.loading || loading}>
                    <SearchWrapper>
                        {!this.state.loading && search.map((item) => this.renderInput(item))}
                        {children}
                        <SearchButton>
                            <Button color={"primary"} size={3} disabled={this.state.submitting || this.state.clearSubmitting} submitting={this.state.submitting} margin={"0 .2em"} onClick={this.onSearch} radius={10}><FontAwesomeIcon icon={"search"} /></Button>
                            {this.state.searchCompleted && <Button color={"danger"} size={3} disabled={this.state.submitting || this.state.clearSubmitting} submitting={this.state.clearSubmitting} margin={"0 .2em"} onClick={this.onClearSearch} radius={10}><FontAwesomeIcon icon={"eraser"} /></Button>}
                        </SearchButton>

                    </SearchWrapper>

                </Card>
                <ButtonContainer>
                    {addButton && <AddButton variant={"success"} onClick={doAddButton}>{addButton}</AddButton>}
                </ButtonContainer>

            </React.Fragment>

        );
    }
}
SearchTable.propTypes = {
    loading: PropTypes.bool,
    search: PropTypes.array.isRequired,
    doFetchData: PropTypes.func,
    sort: PropTypes.object,
    addButton: PropTypes.string,
    doAddButton: PropTypes.func
}
export default withApplicationContext(SearchTable);