import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {withAlert} from "react-alert";
import withRouter from "../../../contexts/withRouter";
import {withAdminContext} from "../../../contexts/AdminContext";
import LoggedIn from "../LoggedIn";
import withAdminAuth from "../../../contexts/withAdminAuth";
import AdminService from "../../../services/AdminService";
import {HomeContainer, MainContainer, SpinnerContainer} from "../../Home/style";
import {Col, Row, Spinner} from "react-bootstrap";
import AdminMusicVideo from "../../../components/ui/MusicVideo/AdminMusicVideo";
import DataList from "../../../components/ui/DataList/DataList";
import Search from "../MembersTop20/Search";

class MembersTop2023 extends Component {
    refContainer = React.createRef();
    constructor(props) {
        super(props);
        const urlSearchParams = new URLSearchParams(props.location.search);
        this.state = {
            loading: true,
            loadingData: true,
            data: [],
            topListId: props.params.id,
            list: [],
            search: {
                keyword: ""
            },
            sort: {
                key: 'total_score',
                dir: 'desc'
            },
            page: 1
        }

    }
    componentDidMount = async () => {
        this.setState({
            loading: false
        })
    }

    handleScroll = async (page) => {
        return await AdminService.topListUserVotes({
            page,
            limit: 20,
            sort_field: 'total_score',
            sort_dir: 'desc',
            ...(this.state.search && this.state.search)
        })
    }
    handleSearch = async (page, sort_field = 'total_score', sort_dir = 'desc', search = null, callback = null) => {
        this.setState({ loadingData: true })
        let data =  await AdminService.topListUserVotes({
            page,
            limit: 20,
            sort_field,
            sort_dir,
            ...(search && search)
        })
        this.setState({
            loadingData: false,
            data: data,
            sort:{
                key: sort_field,
                dir: sort_dir
            },
            search
        }, callback)
    }
    renderSearch = () => {
        return <Search data={this.state.search} doFetchData={this.handleSearch}/>;
    }
    render() {
        const {adminContext} = this.props;
        return (
            <LoggedIn loading={this.state.loading}>
                <MainContainer>
                    {this.state.loading?(
                        <SpinnerContainer>
                            <Spinner animation="border" role="status" variant={"primary"}>
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </SpinnerContainer>
                    ):(
                        <React.Fragment>
                            <DataList
                                columns={[
                                    {key: "id", label: "#", format: "number", align: "left"},
                                    {key: "date", label: "Date Of Ingest", format: "date", align: "left"},
                                    {key: 'code', label: "Clip Code", align: "left"},
                                    {key: 'singer', label: "Artist Name", align: "left"},
                                    {key: 'name', label: "Song Title", align: "left"},
                                    {key: 'total_vote', label: "Total Votes", align: "left"},
                                    {key: 'total_score', label: "Score", align: "left"}
                                ]}
                                data={this.state.data}
                                action={this.handleScroll}
                                search={this.renderSearch}
                                sort={this.state.sort}
                                scrollLoading={true}
                                loading={false}
                                disableNotFound={true}
                                footer={true}
                            />
                        </React.Fragment>
                    )}
                </MainContainer>
            </LoggedIn>
        );
    }
}

export default withAlert()(withApplicationContext(withRouter(withAdminContext(withAdminAuth(MembersTop2023)))));