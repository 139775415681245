import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {withAlert} from "react-alert";
import withRouter from "../../../contexts/withRouter";
import {withAdminContext} from "../../../contexts/AdminContext";
import LoggedIn from "../LoggedIn";
import withAdminAuth from "../../../contexts/withAdminAuth";
import AdminService from "../../../services/AdminService";
import {accountId, msisdn, numberWithCommas, toDate} from "../../../helpers";
import DataList from "../../../components/ui/DataList/DataList";
import Search from "./Search";
import DetailsTable from "../../../components/ui/DetailsTable";
import {ROUTE_ADMIN_UPDATE_MUSIC_VIDEOS} from "../../../routes/routes";
import {PopupText} from "../../../components/ui/AppHeader/style";
import Button from "../../../components/ui/Button";

class MusicVideo extends Component {
    constructor(props) {
        super(props);
        const urlSearchParams = new URLSearchParams(props.location.search);
        this.state = {
            loading: true,
            loadingData: true,
            data: [],
            search: {
                keyword: urlSearchParams.get('uid')
            },
            sort: {
                key: 'id',
                dir: 'desc'
            },
        }

    }
    componentDidMount = async () => {
        this.setState({
            loading: false
        })
    }

    handleScroll = async (page) => {
        return await AdminService.musicVideos({
            page,
            limit: 20,
            sort_field: 'id',
            sort_dir: 'desc',
            ...(this.state.search && this.state.search)
        })
    }
    handleSearch = async (page, sort_field = 'id', sort_dir = 'desc', search = null, callback = null) => {
        this.setState({ loadingData: true })
        let data =  await AdminService.musicVideos({
            page,
            limit: 20,
            sort_field,
            sort_dir,
            ...(search && search)
        })
        this.setState({
            loadingData: false,
            data: data,
            sort:{
                key: sort_field,
                dir: sort_dir
            },
            search
        }, callback)
    }
    renderSearch = () => {
        return <Search data={this.state.search} doFetchData={this.handleSearch}/>;
    }
    onUpdateSearch = (data) => {
        this.setState({
            search: data
        })
    }
    onUpdate = (row) => {
        const {navigate} = this.props;
        navigate(ROUTE_ADMIN_UPDATE_MUSIC_VIDEOS.replaceAll(":id", row.id));
    }

    onDelete = async (row, callback) => {
        const {applicationContext, alert} = this.props;
        this.setState({ description: null });
        let component = (
            <React.Fragment>
                <PopupText>{applicationContext.translator("Are you sure to delete this music video?")}</PopupText>
            </React.Fragment>
        );
        let footer = (
            <React.Fragment>
                <Button color={"success"} submitting={this.state.submitting} onClick={(e) => this.handleDelete(e, row, callback)} margin={"0 .2em"}>{applicationContext.translator("Yes, I'm sure")}</Button>
                <Button color={"danger"} disabled={this.state.submitting} onClick={(e) => applicationContext.onCloseConfirmPopup(e, callback)} margin={"0 .2em"}>{applicationContext.translator("No")}</Button>
            </React.Fragment>

        );
        applicationContext.onOpenConfirmPopup("Delete", component, footer, callback)
    }
    handleDelete = async (e, row, callback) => {
        const {applicationContext, adminContext, alert} = this.props;
        applicationContext.onUpdatePopupSubmitting(true);

        await AdminService.deleteMusicVideo(row.id).then((data) => {
            applicationContext.onCloseConfirmPopup(e, () => {
                this.setState({
                    submitting: false
                }, () => {
                    callback({
                        id: row.id
                    }, "delete")
                })
            })
        }).catch((e) => {
            Object.keys(e.response.data.errors).map((field) => {
                alert.error(applicationContext.translator(e.response.data.errors[field][0]))
            })
        })
    }
    render() {
        const {adminContext} = this.props;
        return (
            <LoggedIn loading={this.state.loading}>
                <DataList
                    columns={[
                        {key: "id", label: "#", format: "number", align: "left"},
                        {key: "created_at", label: "Date Of Ingest", format: "date", align: "left"},
                        {key: 'code', label: "Clip Code", align: "left"},
                        {key: 'singer', label: "Artist Name", align: "left"},
                        {key: 'name', label: "Song Title", align: "left"},
                        {key: "", label: "Actions", actions: [
                            {label: "Update", route: "#", onClick: this.onUpdate, color: "#4caf50"},
                            {label: "Delete", route: "#", onClick: this.onDelete, color: "#d50f0a"},
                        ]}
                    ]}
                    data={this.state.data}
                    action={this.handleScroll}
                    search={this.renderSearch}
                    sort={this.state.sort}
                    scrollLoading={true}
                    loading={false}
                    disableNotFound={true}
                    footer={true}
                />
            </LoggedIn>
        );
    }
}

export default withAlert()(withApplicationContext(withRouter(withAdminContext(withAdminAuth(MusicVideo)))));