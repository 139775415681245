import React from 'react';
import { SortableElement } from 'react-sortable-hoc';
import {SortableItemContainer} from "./style";
import MusicVideo from "../MusicVideo/MusicVideo";

const SortableItem = (props) => {
    let {value, number} = props;
    value.number = number;
    return (
        <SortableItemContainer>
            <MusicVideo data={value} md={12} deleteFromVote={true} isVoted={props.isVoted}/>
        </SortableItemContainer>
    )
}

export default SortableElement(SortableItem);