import React, {Component} from 'react';
import {Container, Logo} from "./style.jsx";
import PropTypes from "prop-types";

class Loading extends Component {

    render() {
        const {transparent, animation, bg} = this.props;
        return (
            <Container transparent={transparent} bg={bg}>
                <Logo src={`${process.env.PUBLIC_URL}/assets/images/logo.png`} animation={animation}/>
            </Container>
        );
    }
}
Loading.propTypes = {
    transparent: PropTypes.bool,
    animation: PropTypes.bool,
    bg: PropTypes.string
}
export default Loading;