import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {withAlert} from "react-alert";
import withRouter from "../../../contexts/withRouter";
import {withAdminContext} from "../../../contexts/AdminContext";
import LoggedIn from "../LoggedIn";
import * as Yup from "yup";
import {Form} from "react-bootstrap";
import InputBox from "../../../components/ui/InputBox/InputBox";
import Button from "../../../components/ui/Button";
import {Formik} from "formik";
import Card from "../../../components/ui/Card";
import withAdminAuth from "../../../contexts/withAdminAuth";
import AdminService from "../../../services/AdminService";

class EnterMasterPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submitting: false,
            loading: true,
            form: {
                password: ""
            }

        }

    }

    onSubmit = async (values, actions) => {
        const {applicationContext, alert, onCallback} = this.props;

        this.setState({ submitting: true });
        await AdminService.checkMasterPassword(values).then((data) => {
            if(data){
                onCallback(values.password);
            }
        }).catch((e) => {
            if(e.response.data.hasOwnProperty('errors') && Object.keys(e.response.data.errors).length > 0){
                Object.keys(e.response.data.errors).map((field) => {
                    actions.setFieldError(field, applicationContext.translator(e.response.data.errors[field][0]))
                })
            }else{
                alert.error(applicationContext.translator(e.response.data.message));
            }

        }).finally(() => {
            this.setState({ submitting: false });
        });
    }

    render() {
        const {applicationContext} = this.props;
        return (
            <Formik
                innerRef={(ref) => this.refForm = ref}
                initialValues={this.state.form}
                validationSchema={Yup.object().shape({
                    password: Yup.string().required(applicationContext.translator("You must enter your ${path}")).label(applicationContext.translator("Password")).min(8, applicationContext.translator("Password must be minimum 8 characters"))
                })}
                onSubmit={this.onSubmit}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue
                  }) =>
                    (
                        <Card maxWidth={800} center={true} top={"2em"}>
                            <Form onSubmit={handleSubmit} style={{ direction: applicationContext.translator("direction") }}>
                                <InputBox type={"password"}
                                          name={"password"}
                                          onChange={handleChange}
                                          placeholder={applicationContext.translator("Master Password")}
                                          value={values.password}
                                          save={"off"}
                                          errors={errors}
                                          touched={touched}
                                />
                                <Form.Group className={"text-center"}>
                                    <Button color={"primary"} fullWidth={true} size={3} submitting={this.state.submitting} type={"submit"}>
                                        {applicationContext.translator("OK")}
                                    </Button>
                                </Form.Group>


                            </Form>
                        </Card>
                    )}
            </Formik>
        );
    }
}

export default withAlert()(withApplicationContext(withRouter(withAdminContext(withAdminAuth(EnterMasterPassword)))));