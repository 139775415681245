import React, {Component} from 'react';
import {withApplicationContext} from "../../contexts/ApplicationContext"
import {Col, Row, Spinner} from 'react-bootstrap';
import {withAccountContext} from "../../contexts/AccountContext";
import MusicVideo from "../../components/ui/MusicVideo";
import {HomeContainer, HomeMusicVideos, HomeVoteList, HomeWrapper, MainContainer, SpinnerContainer} from "./style";
import VotingPopup from "../../components/ui/VotingPopup";
import CommonService from "../../services/CommonService";
class Home extends Component {
    refContainer = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            more: false,
            finish: false,
            page: 1,
            data: []
        }

    }


    componentDidMount = async () => {
        await this.handleScroll();
        this.setState({
            loading: false
        }, () => {
            document.addEventListener('scroll', this.trackScrolling);
        })
    }
    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling);
    }

    handleScroll = async () => {
        let {data, page} = this.state;
        await CommonService.fetchMusicVideosData(page).then((dataResult) => {
            if(dataResult.data.length === 0){
                this.setState({
                    more: false,
                    finish: true
                })
            }else{
                let currentCount = data.length;
                let finish = false;
                let d = dataResult.data;
                if(currentCount + d.length > 83){
                    let diff = d.length - ((currentCount + d.length) - 83);
                    let dd = [];
                    for(let i = 0 ; i < diff; i++) {
                        dd.push(d[i]);
                    }
                    d = dd;
                    finish = true;
                }
                data = data.concat(d);
                this.setState({
                    data,
                    page: page + 1,
                    more: false,
                    finish
                })
            }


        });
    }
    isBottom(el) {
        return el.getBoundingClientRect().bottom <= window.innerHeight;
    }
    trackScrolling = async () => {
        const wrappedElement = document.getElementById('list-of-music-video');
        if (this.isBottom(wrappedElement) && !this.state.more && !this.state.finish){
            this.setState({
                more: true
            }, async () => {
                await this.handleScroll();
            })
        }


    };

    render() {
        const {accountContext} = this.props;
        return (
            <React.Fragment>
                <MainContainer>
                    <HomeContainer ref={(ref) => this.refContainer = ref} popup={accountContext.state.popup}>
                        {this.state.loading?(
                            <SpinnerContainer>
                                <Spinner animation="border" role="status" variant={"primary"}>
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </SpinnerContainer>
                        ):(
                            <React.Fragment>
                                <HomeWrapper>
                                    <HomeMusicVideos md={9}>
                                        <Row id={"list-of-music-video"}>
                                            {this.state.data.map((row, index) => {
                                                row.number = index + 1;
                                                return <MusicVideo data={row} md={4}/>
                                            })}
                                        </Row>
                                        {this.state.more && <SpinnerContainer>
                                            <Spinner animation="border" size={"sm"} role="status" variant={"primary"}>
                                                <span className="visually-hidden">Loading...</span>
                                            </Spinner>
                                        </SpinnerContainer>}
                                    </HomeMusicVideos>
                                    <HomeVoteList show={accountContext.state.popup}>
                                        <VotingPopup title={"Voting List"}  />
                                    </HomeVoteList>
                                </HomeWrapper>

                            </React.Fragment>
                        )}

                    </HomeContainer>

                </MainContainer>

            </React.Fragment>

        );
    }
}

export default withApplicationContext(withAccountContext(Home));