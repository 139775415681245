
export const ROUTE_HOME = `/`;
export const ROUTE_TOP_20 = `/top20`;
export const ROUTE_LOGIN = `/login`;
export const ROUTE_REGISTER = `/register`;
export const ROUTE_REGISTER_MOBILE = `/register/mobile`;
export const ROUTE_REGISTER_COMPLETION = `/register/complete/:hash`;
export const ROUTE_REGISTER_DRAW_RESULTS = `/draws/results`;
export const ROUTE_ACCOUNT = `/account`;
export const ROUTE_ACCOUNT_PROFILE = `/account/profile`;
export const ROUTE_ACCOUNT_PROFILE_EMAIL_UPDATE = `/account/profile/update/email`;
export const ROUTE_ACCOUNT_PROFILE_MOBILE_UPDATE = `/account/profile/update/mobile`;
export const ROUTE_ACCOUNT_MY_TICKETS = `/account/tickets`;
export const ROUTE_ACCOUNT_PLAYED_HISTORY = `/account/history/played`;
export const ROUTE_ACCOUNT_DEPOSIT = `/account/deposit`;
export const ROUTE_ACCOUNT_DEPOSITS_HISTORY = `/account/history/deposits`;
export const ROUTE_FAQ = `/faq`;
export const ROUTE_GAME_MANUAL = `/game-manual`;
export const ROUTE_ABOUT = `/about-us`;

export const ROUTE_LIVE_TABLE = `/play/live`;


export const ROUTE_ADMIN_LOGIN = `/administrator/login`;
export const ROUTE_ADMIN_OVERVIEW = `/administrator/overview`;
export const ROUTE_ADMIN_MONTHLY_YEARLY_OVERVIEW = `/administrator/reports/overview`;
export const ROUTE_ADMIN_PLAYERS_TICKETS = `/administrator/tickets`;
export const ROUTE_ADMIN_PLAYERS = `/administrator/players`;
export const ROUTE_ADMIN_MUSIC_VIDEOS = `/administrator/music_videos`;
export const ROUTE_ADMIN_ADD_MUSIC_VIDEOS = `/administrator/music_videos/add`;
export const ROUTE_ADMIN_UPDATE_MUSIC_VIDEOS = `/administrator/music_videos/update/:id`;
export const ROUTE_ADMIN_TOP_20_LIST = `/administrator/top20`;
export const ROUTE_ADMIN_TOP_20_VOTES_LIST = `/administrator/top20/votes/:id`;
export const ROUTE_ADMIN_TOP_20_MEMBERS = `/administrator/members/top20`;
export const ROUTE_ADMIN_TOP_80_MEMBERS = `/administrator/members/top80`;
export const ROUTE_ADMIN_TOP_2023_MEMBERS = `/administrator/members/top2023`;
export const ROUTE_ADMIN_PENDING_DEPOSITS_HISTORY = `/administrator/history/deposits/pending`;
export const ROUTE_ADMIN_SEND_EMAILS = `/administrator/players/email`;
export const ROUTE_ADMIN_ENTER_WINNING_NUMBER = `/administrator/draws/enter`;
export const ROUTE_ADMIN_SEARCH = `/administrator/search`;

