import api from "./api";

const fetchCommonData = () => {
    return api.get("/common/all").then((response) => {
        return response.data;
    });
};

const fetchMusicVideosData = async (page) => {
    let limit = 32;
    return await api.get("/common/mv/list?page="+page+"&limit=" + limit + "&sort_field=id&sort_dir=desc").then((response) => {
        return response.data;
    });
};

const fetchTop20Data = async (page) => {
    let limit = 32;
    return await api.get("/common/top20/list?page="+page+"&limit=" + limit).then((response) => {
        return response.data;
    });
};

const fetchTop20ByDate = async (date) => {
    return await api.get("/common/top20/list?page=1&limit=" + 20+ "&date=" + date).then((response) => {
        return response.data;
    });
};

const CommonService = {
    fetchCommonData,
    fetchMusicVideosData,
    fetchTop20Data,
    fetchTop20ByDate
};

export default CommonService;