import React from 'react';
import {ROUTE_ADMIN_LOGIN} from "../routes/routes";
import StorageService from "../services/StorageService";
import {useLocation, Navigate} from 'react-router-dom';

const withAdminAuth = WrappedComponent =>  (props) => {
    let loggedIn = props.adminContext.state.loggedIn;
    if(!loggedIn) {
        let location = props.location??useLocation();
        StorageService.set('redirect', location.pathname);
        return <Navigate replace to={ROUTE_ADMIN_LOGIN} />;
    }

    return (
        <WrappedComponent
            {...props}

        />
    );
};

export default (withAdminAuth);