import React, {Component} from 'react';
import {
    Container,
    PopupContainer,
    Header,
    Title,
    Body,
    Icon,
    Footer,
    SendButton,
    SelectedText,
    SubmittedSuccessfully,
    ErrorText, Description, SelectedNumber, PopupCloseIcon
} from "./style.jsx";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {SortIcon} from "../DataList/style";
import {withVotingContext} from "../../../contexts/VotingContext";
import SortableList from './SortableList';
import { arrayMoveImmutable } from 'array-move';
import {withAccountContext} from "../../../contexts/AccountContext";
import LoginForm from "../../../scenes/Login/Forms/LoginForm";
import {Spinner} from "react-bootstrap";
import moment from "jalali-moment";
import {toDate, zeroPad} from "../../../helpers";
import AccountService from "../../../services/AccountService";
import {SpinnerContainer} from "../../../scenes/Home/style";

class VotingPopup extends Component {
    refContainer = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            submitting: false,
            submitted: false,
            data: []
        }
    }

    componentDidMount = async () => {
        const {applicationContext, accountContext} = this.props;
        setTimeout(() => {
            this.refContainer.style.right = 0;
            this.refContainer.style.opacity = 1;
        }, 100)
        this.setState({
            loading: false
        })
    }

    onSortEnd = ({ oldIndex, newIndex }) => {
        const {accountContext} = this.props;
        accountContext.onUpdateState({
            votingList: arrayMoveImmutable(accountContext.state.votingList, oldIndex, newIndex)
        })
    };
    onShouldCancelStart = (e) => {
        if(["SVG", "PATH", "BUTTON"].includes(e.target.tagName.toUpperCase()))
            return true;

    }
    onPushVote = async (e) => {
        const {applicationContext, accountContext} = this.props;
        if(!accountContext.state.loggedIn)
            return applicationContext.onOpenPopup("Log In", <LoginForm onCallback={this.handlePushVote} />);

        await this.handlePushVote();
    }
    handlePushVote = async () => {
        const {applicationContext, accountContext, alert, ticketCreatingContext} = this.props;
        this.setState({ submitting: true })
        applicationContext.onClosePopup();
        try{
            let music_videos = [];
            accountContext.state.votingList.map((vote) => music_videos.push(vote.id))

            await AccountService.vote({
                top_list_id: applicationContext.state.topListId,
                music_videos: music_videos
            }).then((data) => {
                this.setState({ submitted: true });
            }).finally(() => {
                this.setState({ submitting: false });
            })
        }catch (err){
            this.setState({ submitting: false });
        }

    }
    renderDate = () => {
        const {applicationContext} = this.props;
        return toDate(applicationContext.state.next2VoteDate, applicationContext.state.locale);
    }
    render() {
        const {applicationContext, accountContext, title, children, footer, onClose, submitting} = this.props;

        return <Container dir={applicationContext.translator("direction")} ref={(ref) => this.refContainer = ref}>
            <PopupContainer size={"md"}>
                <Header>
                    <Title>Voting List <SelectedNumber>{accountContext.state.votingList.length}/10</SelectedNumber> <PopupCloseIcon onClick={(e) => accountContext.onClosePopup()}><FontAwesomeIcon icon={"times"} /></PopupCloseIcon></Title>
                </Header>
                <Body>
                    {this.state.loading?(
                        <SpinnerContainer>
                            <Spinner animation="border" role="status" variant={"primary"}>
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </SpinnerContainer>
                    ):(
                        <React.Fragment>
                            {accountContext.state.votingList.length > 0 && !this.state.submitted && !accountContext.state.isVoted && <Description>Drag and drop if you want to change the position</Description>}

                            <React.Fragment>
                                <SortableList items={accountContext.state.votingList}
                                              helperClass={"sort-list-item"}
                                              isVoted={accountContext.state.isVoted || this.state.submitted}
                                              shouldCancelStart={this.onShouldCancelStart}
                                              onSortEnd={this.onSortEnd} />
                            </React.Fragment>
                            {!this.state.submitted && !accountContext.state.isVoted && accountContext.state.votingList.length >= 10 && <SendButton variant={"primary"} disabled={this.state.submitting} onClick={this.onPushVote}>{this.state.submitting?(<Spinner animation="border" size={"sm"} role="status" variant={"primary"} />):("Vote and send")}</SendButton>}
                            {this.state.submitted &&
                                <SubmittedSuccessfully>
                                    <FontAwesomeIcon icon={"check-circle"} /><br/>
                                    {applicationContext.translator("Your selected music videos score has been submitted successfully.")}<br/>
                                    {applicationContext.translator("Next voting is open from next week, ")}{this.renderDate()}<br/>
                                </SubmittedSuccessfully>
                            }
                            {accountContext.state.isVoted &&
                                <ErrorText>
                                    {applicationContext.translator("You can vote once a week.")}<br/>
                                    {applicationContext.translator("You have already voted for this week next voting is open from next week, ")}{this.renderDate()}<br/>
                                </ErrorText>
                            }
                        </React.Fragment>
                    )}
                </Body>

            </PopupContainer>

        </Container>;
    }
}
VotingPopup.propTypes = {
    size: PropTypes.string.isRequired,
    title: PropTypes.string,
    footer: PropTypes.any,
    onClose: PropTypes.func.isRequired,
    submitting: PropTypes.bool
}
export default withApplicationContext(withAccountContext(VotingPopup));