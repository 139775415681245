import React, {Component} from 'react';
import {Container, PickerButton, CalendarContainer} from "./style.jsx";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import PropTypes from 'prop-types';
import Calendar from "react-calendar";
import {convertToDate, toDate} from "../../../helpers";

class DatePicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            picker: false,
            date: null,
            nativeDate: null
        }
    }
    onChange = (date) => {
        const {applicationContext, format, onChange, searchOnChange} = this.props;
        let formatDate = convertToDate(date, applicationContext.state.locale, 'YYYY/MM/DD HH:mm:ss', format??'dddd, D MMMM YYYY')
        this.setState({
            picker: false,
            date: formatDate,
            nativeDate: date
        })
        if(onChange) {
            onChange(date, formatDate);
            if(searchOnChange)
                return searchOnChange(1)
        }
    }
    onClickPicker = (e) => {
        this.setState({
            picker: !this.state.picker
        })
    }
    render() {
        const {placeholder, margin, blackoutDates, enableDays} = this.props;
        const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
        return (
            <Container margin={margin}>
                <PickerButton onClick={this.onClickPicker}>{this.state.date??placeholder}</PickerButton>
                {this.state.picker && <CalendarContainer>
                    <Calendar value={this.state.nativeDate} onChange={this.onChange} selectRange={false}
                              tileDisabled={({date}) => {
                                  if(blackoutDates)
                                    return blackoutDates.includes(date.getDate())
                                  if(enableDays){
                                      return !enableDays.includes(weekday[date.getDay()].toLowerCase())
                                  }
                              }}
                    />
                </CalendarContainer>}
            </Container>
        );
    }
}
DatePicker.propTypes = {
    placeholder: PropTypes.string,
    margin: PropTypes.string,
    format: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
    blackoutDates: PropTypes.array,
    enableDays: PropTypes.array
}
export default withApplicationContext(DatePicker);