import React, {Component} from 'react';
import {Route, Routes} from "react-router-dom";
import {withApplicationContext} from "../contexts/ApplicationContext"
import {
    ROUTE_HOME, ROUTE_TOP_20
} from "./routes";
import Home from "../scenes/Home/Home";
import BaseMaster from "../scenes/BaseMaster";
import Top20 from "../scenes/Top20";

class PublicRouter extends Component {
    render() {
        return (
            <Routes>
                <Route  path={ROUTE_HOME} element={<BaseMaster><Home /></BaseMaster>} />
                <Route  path={ROUTE_TOP_20} element={<BaseMaster><Top20 /></BaseMaster>} />
                {/*<Route exact={true} path={"/:slug"} element={<BaseMaster><Page /></BaseMaster>} />*/}
            </Routes>
        );
    }
}

export default withApplicationContext((PublicRouter));