import React from 'react';
import { SortableElement } from 'react-sortable-hoc';
import {SortableItemContainer} from "./style";
import AdminMusicVideo from "../../../components/ui/MusicVideo/AdminMusicVideo";

const SortableItem = (props) => {
    let {value, number} = props;
    value.rank = number;
    return (
        <SortableItemContainer>
            <AdminMusicVideo data={value} md={12} deleteFromVote={true} onDeleteMusicFromList={props.onDeleteMusicFromList}/>
        </SortableItemContainer>
    )
}

export default SortableElement(SortableItem);