import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {withAlert} from "react-alert";
import withRouter from "../../../contexts/withRouter";
import {withAdminContext} from "../../../contexts/AdminContext";
import LoggedIn from "../LoggedIn";
import withAdminAuth from "../../../contexts/withAdminAuth";
import AdminService from "../../../services/AdminService";
import {accountId, msisdn, numberWithCommas, toDate} from "../../../helpers";
import DataList from "../../../components/ui/DataList/DataList";
import Search from "./Search";
import DetailsTable from "../../../components/ui/DetailsTable";
import {ROUTE_ADMIN_TOP_20_VOTES_LIST, ROUTE_ADMIN_UPDATE_MUSIC_VIDEOS} from "../../../routes/routes";


class Top20 extends Component {
    constructor(props) {
        super(props);
        const urlSearchParams = new URLSearchParams(props.location.search);
        this.state = {
            loading: true,
            loadingData: true,
            data: [],
            search: {
                date: "",
                is_pending: 1
            },
            sort: {
                key: 'id',
                dir: 'desc'
            },
        }

    }
    componentDidMount = async () => {

        this.setState({ loading: false, loadingData: false })
    }

    fetchData = async (page, sort_field = 'num', sort_dir = 'asc', search = null, callback = null) => {
        this.setState({ loadingData: true })
        let data = await AdminService.topListRounds({
            page,
            limit: 10,
            sort_field,
            sort_dir,
            ...(search??this.state.search)
        });
        this.setState({
            loadingData: false,
            data: data,
            search: search??this.state.search,
            sort:{
                key: sort_field,
                dir: sort_dir
            }
        }, callback)
    }
    renderSearch = () => {
        return <Search data={this.state.search} doFetchData={this.fetchData}/>;
    }
    onUpdateSearch = (data) => {
        this.setState({
            search: data
        })
    }
    onVotes = (row) => {
        const {navigate} = this.props;
        navigate(ROUTE_ADMIN_TOP_20_VOTES_LIST.replaceAll(":id", row.id));
    }
    render() {
        const {adminContext} = this.props;
        return (
            <LoggedIn loading={this.state.loading}>
                <DataList
                    columns={[
                        {key: "id", label: "#", format: "number"},
                        {key: 'date', label: "Date", format: "date"},
                        {key: "total_votes", label: "Votes"},
                        {key: "", label: "Actions", actions: [
                            {label: "Create Top20 List", route: "#", onClick: this.onVotes, color: "#4caf50"},
                        ]}
                    ]}
                    data={this.state.data}
                    action={this.fetchData}
                    search={this.renderSearch}
                    sort={this.state.sort}
                    loading={this.state.loadingData}
                    disableNotFound={true}
                />
            </LoggedIn>
        );
    }
}

export default withAlert()(withApplicationContext(withRouter(withAdminContext(withAdminAuth(Top20)))));