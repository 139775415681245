import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {withAlert} from "react-alert";
import withRouter from "../../../contexts/withRouter";
import {withAdminContext} from "../../../contexts/AdminContext";
import {Container, NavigateArrow, Table, TableCell, TableHead, TableRow, Top20Container} from "./style";
import LoggedIn from "../LoggedIn";
import withAdminAuth from "../../../contexts/withAdminAuth";
import Card from "../../../components/ui/Card";
import {convertToDate, toDate} from "../../../helpers";
import * as Yup from "yup";
import {Col, Form, Row} from "react-bootstrap";
import {Formik} from "formik";
import DatePicker from "../../../components/ui/DatePicker/DatePicker";
import CommonService from "../../../services/CommonService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
Yup.addMethod(Yup.string, 'requiredIf', function(list, message) {
    return this.test('requiredIf', message, function(value) {
        const { path, createError } = this;
        var anyHasValue = list.some(value => {
            return Boolean(document.querySelector(`input[name="${value}"]`).value);

        });
        return !value && !anyHasValue
            ? createError({ path, message })
            : true;
    });
});
class PreviousTop20 extends Component {
    refForm = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loadingData: false,
            searchDone: false,
            navigate: 0,
            form: {
                user_id: "",
                email: ""
            },
            data: [],
            search: {

            },
            sort: {
                key: 'id',
                dir: 'desc'
            },
        }

    }
    componentDidMount = async () => {
        const {applicationContext} = this.props;
        await this.handleLoadData(applicationContext.state.lastTop20Date)
    }
    handleLoadData = async (date) => {
        this.setState({
            search:{
                date
            },
            loading: false
        }, async () => {
            await this.fetchData(1, 'id', 'desc', {
                date
            });
        })
    }
    onPrev = async (e) => {
        this.setState({
            navigate: this.state.navigate + 1
        }, async () => {
            const date = new Date(this.state.search.date);
            const originalDay = date.getDate();
            const newDay = originalDay - 7;
            date.setDate(newDay);
            await this.handleLoadData(convertToDate(date, "en", 'YYYY/MM/DD HH:mm:ss', 'YYYY-MM-DD'));
        })
    }
    onNext = async (e) => {
        this.setState({
            navigate: this.state.navigate - 1
        }, async () => {
            const date = new Date(this.state.search.date);
            const originalDay = date.getDate();
            const newDay = originalDay + 7;
            date.setDate(newDay);
            await this.handleLoadData(convertToDate(date, "en", 'YYYY/MM/DD HH:mm:ss', 'YYYY-MM-DD'));
        })
    }

    onSearch = async (values, actions) => {
        this.setState({
            searchDone: true
        }, async () => {
            await this.fetchData(1, this.state.sort.key, this.state.sort.dir, values)
        })

    }
    componentWillUnmount() {
        const {adminContext} = this.props;
        adminContext.handleChangeMenu("opened");
    }

    fetchData = async (page, sort_field = 'id', sort_dir = 'desc', search = null, callback = null) => {
        const {adminContext} = this.props;
        this.setState({ loadingData: true })
        let data = await CommonService.fetchTop20ByDate(search.date);
        this.setState({
            loadingData: false,
            data: data,
            search: search??this.state.search,
            searchDone: true,
            sort:{
                key: sort_field,
                dir: sort_dir
            }
        }, () => {
            adminContext.handleChangeMenu("closed");
            if(callback)
                return callback()
        })
    }
    onChange = (field, value) => {
        this.setState({
            search: {
                ...this.state.search,
                [field]: value
            }
        })
    }
    renderDate = (date) => {
        const {applicationContext} = this.props;
        return toDate(date, applicationContext.state.locale);
    }
    render() {
        const {applicationContext} = this.props;
        return (
            <LoggedIn loading={this.state.loading}>

                {!this.state.loading &&
                    <Top20Container>
                        <NavigateArrow right={true} onClick={this.onPrev}>
                            <FontAwesomeIcon icon={"chevron-right"} />
                        </NavigateArrow>
                        {this.state.navigate > 0 && <NavigateArrow left={true} onClick={this.onNext}>
                            <FontAwesomeIcon icon={"chevron-left"} />
                        </NavigateArrow>}
                        {this.state.searchDone?(
                            <Row>
                                <Col md={12}>
                                    <Formik
                                        innerRef={(ref) => this.refForm = ref}
                                        initialValues={this.state.search}
                                        validationSchema={Yup.object().shape({
                                            date: Yup.string().required(applicationContext.translator("At least one field is required."))
                                        })}
                                        onSubmit={this.onSearch}
                                    >
                                        {({
                                              values,
                                              errors,
                                              touched,
                                              handleChange,
                                              handleSubmit,
                                              isSubmitting,
                                              setFieldValue
                                          }) =>
                                            (
                                                <Card maxWidth={"100%"} center={true} bottom={"1em"} padding={".5em"}>
                                                    <Form onSubmit={handleSubmit} style={{ direction: applicationContext.translator("direction") }} ref={(ref) => this.refForm = ref}>
                                                        <DatePicker
                                                            placeholder={toDate(this.state.search.date, "en")}
                                                            format={'YYYY-MM-DD'}
                                                            onChange={(date, formatDate) => {
                                                                setFieldValue('date', formatDate);
                                                                handleSubmit();
                                                            }}
                                                            value={values.date}
                                                            enableDays={["thursday"]}
                                                        />
                                                    </Form>
                                                </Card>
                                            )}
                                    </Formik>
                                </Col>
                                {this.state.data.map((row, index) => {
                                    return (
                                        <Col md={3}>
                                            <Table>
                                                <TableRow>
                                                    <TableHead colSpan={4} header={true}>{this.renderDate(row.date)}</TableHead>
                                                </TableRow>
                                                <TableRow>
                                                    <TableHead>#</TableHead>
                                                    <TableHead></TableHead>
                                                    <TableHead>Code</TableHead>
                                                </TableRow>
                                                {row['top_20_list'].map((top20Row, i) => {
                                                    return (
                                                        <TableRow>
                                                            <TableCell center={true}>{top20Row.rank}</TableCell>
                                                            <TableCell>
                                                                <span>{top20Row['music_video']['singer']}</span> - {top20Row['music_video']['name']}
                                                            </TableCell>
                                                            <TableCell center={true}>
                                                                {top20Row['music_video']['code']}
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })}
                                            </Table>
                                        </Col>
                                    )
                                })}


                            </Row>
                        ):(
                            <Container>

                            </Container>
                        )}
                        {/*{this.state.searchDone && <DataList*/}
                        {/*    columns={[*/}
                        {/*        {key: "id", label: "#", format: "number"},*/}
                        {/*        {key: "date", label: "Date Of Ingest", format: "date"},*/}
                        {/*        {key: 'code', label: "Clip Code"},*/}
                        {/*        {key: 'name', label: "Song Title"},*/}
                        {/*        {key: 'singer', label: "Artist Name"},*/}
                        {/*        {key: 'total_vote', label: "Total Votes"},*/}
                        {/*        {key: 'total_score', label: "Score"}*/}
                        {/*    ]}*/}
                        {/*    data={this.state.data}*/}
                        {/*    action={this.fetchData}*/}
                        {/*    sort={this.state.sort}*/}
                        {/*    loading={this.state.loadingData}*/}
                        {/*    footer={true}*/}
                        {/*/>}*/}
                    </Top20Container>
                }

            </LoggedIn>
        );
    }
}

export default withAlert()(withApplicationContext(withRouter(withAdminContext(withAdminAuth(PreviousTop20)))));