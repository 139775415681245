import i18n from './i18n';
import 'bootstrap/dist/css/bootstrap.min.css';
import './theme.css';
import './assets/css/App.module.css';
import './assets/css/main.css';
import "./functions";
import 'react-calendar/dist/Calendar.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {library} from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import {Component, Suspense, StrictMode} from "react";
import {Translation} from 'react-i18next'
import Loading from "./components/ui/Loading";
import {ApplicationProvider} from "./contexts/ApplicationContext";
import {positions, Provider} from "react-alert";
import CustomAlertTemplate from "./components/ui/Alert";
import {Route, Switch, BrowserRouter} from "react-router-dom";
import Routers from "./routes/Routers";
import StorageService from "./services/StorageService";
import CommonService from "./services/CommonService";
import {toHashMap} from "./helpers";

const iconList = Object.keys(Icons)
    .filter((key) => key !== 'fas' && key !== 'prefix')
    .map((icon) => Icons[icon]);

library.add(...iconList);



class App extends Component {
    voice = null;
    constructor(props) {
        super(props);
        this.state = {
            locale: process.env.REACT_APP_DEFAULT_LOCALE,
            ticketPrice: 1,
            config: {},
            states: [],
            nextVoteDate: null,
            next2VoteDate: null,
            topListId: null,
            lastTop20Date: null,
            wallet: "",
            popup: {
                show: false,
                title: "",
                component: null,
                size: "md"
            },
            confirmPopup: {
                show: false,
                title: "",
                component: null,
                footer: null,
                size: "md",
                onClose: null,
                submitting: false
            },
            loading: true
        };
    }


    componentDidMount = async () => {
        document.body.classList.add("light-theme")
        let common = await CommonService.fetchCommonData();

        this.setState({
            config: toHashMap(common['config'], 'key'),
            nextVoteDate: common['next_vote_date'],
            next2VoteDate: common['next_2_vote_date'],
            topListId: common['next_top_list_id'],
            lastTop20Date: common['last_top_20'],
            loading: false
        })
    }
    handleChangePopupTitle = (title) => {
        this.setState({
            popup: {
                ...this.state.popup,
                title
            }
        })
    }
    onOpenPopup = (title, component, size = "md", show = true) => {
        let popup = {
            show,
            title,
            component,
            size
        }
        this.setState({
            popup
        })
    }
    onClosePopup = () => {
        this.onOpenPopup("", null, "md",false)
    }
    doUpdateLocale = (locale, callback) => {
        this.setState({
            locale
        }, async () => {
            await i18n.changeLanguage(locale);
            return callback();
        })
    }

    onOpenConfirmPopup = (title,
                          component,
                          footer,
                          onClose = null,
                          size = "md",
                          show = true,
                          callback = () => {}
    ) => {
        let popup = {
            show,
            title,
            component,
            footer,
            size,
            onClose,
            submitting: false
        }
        this.setState({
            confirmPopup: popup
        }, callback)
    }
    onCloseConfirmPopup = (e, callback = () => {}) => {
        this.onOpenConfirmPopup("", null,null, null, "md",false, callback)
    }
    onUpdatePopupSubmitting = (submitting) => {
        this.setState({
            confirmPopup: {
                ...this.state.confirmPopup,
                submitting
            }
        })
    }
    updateLastLottery = (data, callback) => {
        this.setState({
            lastLottery: data
        }, callback)
    }

    playSound = (name) => {
        if(this.voice && !this.voice.paused) return false;
        this.voice = new Audio(`/assets/voices/${this.state.locale}/${name}.mp3`);
        this.voice.play();
    }

    render() {
        return (
            <StrictMode>
                {this.state.loading?(
                    <Loading animation={true}/>
                ):(
                    <Suspense fallback={<Loading bg={"transparent"}/>}>
                        <Translation>
                            {(t, {i18n}) => (
                                <ApplicationProvider value={{
                                    state: this.state,
                                    doChangeLanguage: this.doChangeLanguage,
                                    updateLastLottery: this.updateLastLottery,
                                    handleChangePopupTitle: this.handleChangePopupTitle,
                                    onOpenPopup: this.onOpenPopup,
                                    onClosePopup: this.onClosePopup,
                                    onOpenConfirmPopup: this.onOpenConfirmPopup,
                                    onCloseConfirmPopup: this.onCloseConfirmPopup,
                                    onUpdatePopupSubmitting: this.onUpdatePopupSubmitting,
                                    doUpdateLocale: this.doUpdateLocale,
                                    playSound: this.playSound,
                                    translator: t,
                                    i18n: i18n
                                }}>
                                    <Provider template={CustomAlertTemplate} {...{
                                        timeout: 20000,
                                        position: positions.TOP_CENTER
                                    }}>
                                        <BrowserRouter>
                                            <Routers/>
                                        </BrowserRouter>
                                    </Provider>
                                </ApplicationProvider>
                            )}
                        </Translation>

                    </Suspense>
                )}

            </StrictMode>


        );
    }
}

export default App;
