import styled from "styled-components";
import {Form} from 'react-bootstrap';

export const LoginContainer = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 5em auto;
`
export const BlockText = styled(Form.Text)`
  font-size: 1em;
  color: #333;
  & a{
    display: inline;
    color: var(--color-secondary);
    margin: 0 .3em;
  }
`
export const FormTitle = styled.h5`
  margin-bottom: 1.5em;
  font-size: 1.2em;
  font-weight: 500;
  color: #fff;
  text-align: center;
`
export const OrText = styled.div`
  padding: 1em;
`
export const Flex = styled.div`
  display: flex;
  direction: ltr;
  & .dropdown{
    width: 100%;
  }
  & .month-dropdown{
    margin: 0 .2em
  }
`
export const ProfilePasswordHelp = styled.div`
  margin-top: 1em;
  & ul{
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    & li{
      width: 20%;
      padding: 0.5em;
      text-align: center;
      background: #424242;
      margin: 0 0.2em;
      border-radius: 5px;
      & svg{
        display: none;
      }
    }
  }
  
  
`
export const EmailVerificationSuccessfully = styled.div`
  color: rgb(52 209 59);
  text-align: center;
  font-size: 1.2em;
  font-weight: 500;
  line-height: 1.7em;
  margin: 2em 0;
  & svg{
    font-size: 4em;
    margin-bottom: 0.3em;
  }
`