import React from "react";
const VotingContext = React.createContext({});
const VotingProvider = VotingContext.Provider;
const VotingConsumer = VotingContext.Consumer;
const withVotingContext = Component => React.forwardRef((props, ref) => (
    <VotingConsumer>
        {(response) => {
            return <Component {...props} votingContext={response} ref={ref}/>;
        }}
    </VotingConsumer>
));
export { VotingProvider, VotingConsumer, withVotingContext };
