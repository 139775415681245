import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {withAlert} from "react-alert";
import withRouter from "../../../contexts/withRouter";
import {withAdminContext} from "../../../contexts/AdminContext";
import {Container} from "./style";
import LoggedIn from "../LoggedIn";
import withAdminAuth from "../../../contexts/withAdminAuth";
import Card from "../../../components/ui/Card";
import AdminService from "../../../services/AdminService";
import {accountId, msisdn, numberWithCommas, toDate} from "../../../helpers";
import * as Yup from "yup";
import {Form} from "react-bootstrap";
import InputBox from "../../../components/ui/InputBox/InputBox";
import Button from "../../../components/ui/Button";
import {Formik} from "formik";
import DataList from "../../../components/ui/DataList/DataList";
import DetailsTable from "../../../components/ui/DetailsTable/DetailsTable";
Yup.addMethod(Yup.string, 'requiredIf', function(list, message) {
    return this.test('requiredIf', message, function(value) {
        const { path, createError } = this;
        var anyHasValue = list.some(value => {
            return Boolean(document.querySelector(`input[name="${value}"]`).value);

        });
        return !value && !anyHasValue
            ? createError({ path, message })
            : true;
    });
});
class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loadingData: false,
            searchDone: false,
            form: {
                user_id: "",
                email: ""
            },
            data: [],
            search: {

            },
            sort: {
                key: 'id',
                dir: 'desc'
            },
        }

    }
    componentDidMount = async () => {

    }

    onSearch = async (values, actions) => {
        this.setState({
            searchDone: true
        }, async () => {
            await this.fetchData(1, this.state.sort.key, this.state.sort.dir, values)
        })

    }

    fetchData = async (page, sort_field = 'id', sort_dir = 'desc', search = null, callback = null) => {
        this.setState({ loadingData: true })
        let data = await AdminService.musicVideos({
            page,
            limit: 10,
            sort_field,
            sort_dir,
            ...(search??this.state.search)
        });
        this.setState({
            loadingData: false,
            data: data,
            search: search??this.state.search,
            sort:{
                key: sort_field,
                dir: sort_dir
            }
        }, callback)
    }
    onView = (row) => {
        const {applicationContext} = this.props;
        let data = [
            {title: "ID", value: "#"+(row.id)},
            {title: "Code", value: row.code},
            {title: "Name", value: row.name},
            {title: "Singer", value: row.singer},
            {title: "Created Time", value: toDate(row.created_at, applicationContext.state.locale, true)},
            {title: "Updated Time", value: toDate(row.updated_at, applicationContext.state.locale, true)}
        ];
        return applicationContext.onOpenPopup("Music Video Information", <DetailsTable data={data} />);
    }
    render() {
        const {applicationContext} = this.props;
        return (
            <LoggedIn loading={this.state.loading}>

                {!this.state.loading &&
                    <React.Fragment>
                        <Container>
                            <Formik
                                innerRef={(ref) => this.refForm = ref}
                                initialValues={this.state.form}
                                validationSchema={Yup.object().shape({
                                    code: Yup.string().requiredIf(['name', 'singer'], applicationContext.translator("At least one field is required.")),
                                    name: Yup.string().requiredIf(['code', 'singer'], applicationContext.translator("At least one field is required.")),
                                    singer: Yup.string().requiredIf(['code', 'name'], applicationContext.translator("At least one field is required."))
                                })}
                                onSubmit={this.onSearch}
                            >
                                {({
                                      values,
                                      errors,
                                      touched,
                                      handleChange,
                                      handleSubmit,
                                      isSubmitting,
                                      setFieldValue
                                  }) =>
                                    (
                                        <Card maxWidth={800} center={true} top={"2em"}>
                                            <Form onSubmit={handleSubmit} style={{ direction: applicationContext.translator("direction") }}>
                                                <InputBox type="text"
                                                          name={"code"}
                                                          onChange={handleChange}
                                                          placeholder={applicationContext.translator("Clip code")}
                                                          value={values.code}
                                                          errors={errors}
                                                          touched={touched}
                                                />
                                                <InputBox type="text"
                                                          name={"singer"}
                                                          onChange={handleChange}
                                                          placeholder={applicationContext.translator("Artist name")}
                                                          value={values.singer}
                                                          errors={errors}
                                                          touched={touched}
                                                />
                                                <InputBox type="text"
                                                          name={"name"}
                                                          onChange={handleChange}
                                                          placeholder={applicationContext.translator("Song title")}
                                                          value={values.name}
                                                          errors={errors}
                                                          touched={touched}
                                                />

                                                <Form.Group className={"text-center"}>
                                                    <Button color={"primary"} fullWidth={true} size={3} submitting={this.state.submitting} className={"btn btn-account"} type={"submit"}>
                                                        {applicationContext.translator("Search")}
                                                    </Button>
                                                </Form.Group>


                                            </Form>
                                        </Card>
                                    )}
                            </Formik>


                        </Container>
                        {this.state.searchDone && <DataList
                            columns={[
                                {key: "id", label: "#", format: "number"},
                                {key: 'code', label: "Code"},
                                {key: 'name', label: "Name"},
                                {key: 'singer', label: "Singer"},
                                {key: "created_at", label: "Created Date", format: "date"},
                                {key: "", label: "Actions", actions: [
                                        {label: "View", route: "#", onClick: this.onView, submitting: true}
                                    ]}
                            ]}
                            data={this.state.data}
                            action={this.fetchData}
                            sort={this.state.sort}
                            loading={this.state.loadingData}
                        />}
                    </React.Fragment>
                }

            </LoggedIn>
        );
    }
}

export default withAlert()(withApplicationContext(withRouter(withAdminContext(withAdminAuth(Search)))));