import React, {Component} from 'react';
import {Container, Logo} from "./style.jsx";
import PropTypes from "prop-types";

class Card extends Component {

    render() {
        const {children, loading} = this.props;
        return (
            <Container {...this.props} className={"card"}>
                {loading?"":children}
            </Container>
        );
    }
}
Card.propTypes = {
    maxWidth: PropTypes.number,
    center: PropTypes.bool,
    top: PropTypes.bool,
    bottom: PropTypes.bool,
    padding: PropTypes.string,
    margin: PropTypes.string,
    loading: PropTypes.bool
}
export default Card;