import React, {Component} from 'react';
import * as Yup from "yup";
import {Col, Form, Row} from "react-bootstrap";
import {OrText, FormTitle, Flex, ProfilePasswordHelp, EmailVerificationSuccessfully} from "../style";
import InputBox from "../../../components/ui/InputBox/InputBox";
import Button from "../../../components/ui/Button/Button";
import {NavLink} from "react-router-dom";
import {ROUTE_ACCOUNT, ROUTE_REGISTER} from "../../../routes/routes";
import {Formik} from "formik";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import withRouter from "../../../contexts/withRouter";
import PropTypes from "prop-types";
import AuthService from "../../../services/AuthService";
import TokenService from "../../../services/TokenService";
import AccountService from "../../../services/AccountService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {dayRange, parseErrorMessage, passwordCheck, yearRange, zeroPad} from "../../../helpers";
import {withAlert} from "react-alert";
import {withAccountContext} from "../../../contexts/AccountContext";
import Dropdown from "../../../components/ui/Dropdown";
import {InputError} from "../../../components/ui/InputBox/style";
import moment from "jalali-moment";

class LoginForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submitting: false,
            register: props.register,
            registerEmailVerify: false,
            passwordHelp: "",
            forms: {
                login: {
                    email: "",
                    password: ""
                },
                register: {
                    email: "",
                    first_name: "",
                    last_name: "",
                    password: "",
                    year_of_birth: "",
                    month_of_birth: "",
                    day_of_birth: "",
                    gender: ""
                }
            }
        }
    }
    handleLoginSubmit = async (values, actions) => {
        const {applicationContext, accountContext, alert, onCallback} = this.props;
        this.setState({ submitting: true });
        try {
            const token  = await AuthService.login(values.email, values.password);
            TokenService.updateLocalAccessToken(token);
            if(onCallback) {
                this.setState({ submitting: false });
                return onCallback();
            }
            await AccountService.getUserBoard().then((data) => {
                onCallback();
                accountContext.updateUserInfo(data, onCallback);
            });

        } catch (err) {
            this.setState({ submitting: false });
            alert.error(applicationContext.translator(parseErrorMessage(err)));
        }
    }
    handleRegisterSubmit = async (values, actions) => {
        const {applicationContext, accountContext, alert, onCallback} = this.props;
        this.setState({ submitting: true });
        let date_of_birth = values.year_of_birth+"-"+zeroPad(values.month_of_birth, 2)+"-"+zeroPad(values.day_of_birth, 2);
        if(applicationContext.state.locale === "fa"){
            date_of_birth = values.year_of_birth+"/"+zeroPad(values.month_of_birth, 2)+"/"+zeroPad(values.day_of_birth, 2);
            let date = moment.from(date_of_birth, 'fa', 'YYYY/MM/DD');
            date_of_birth = (date['_a'][0].toString()+"-"+zeroPad((date['_a'][1] + 1).toString(), 2)+"-"+zeroPad(date['_a'][2].toString(), 2))
        }
        let data = values;
        data.date_of_birth = date_of_birth;
        data.locale = applicationContext.state.locale;
        delete data.year_of_birth;
        delete data.month_of_birth;
        delete data.day_of_birth;
        AuthService.register(data).then(async (result) => {
            this.setState({
                registerEmailVerify: true
            })
        }).catch((e) => {
            if(e.response.data.hasOwnProperty('errors') && Object.keys(e.response.data.errors).length > 0){
                Object.keys(e.response.data.errors).map((field) => {
                    actions.setFieldError(field, applicationContext.translator(e.response.data.errors[field][0]))
                })
            }else{
                alert.error(applicationContext.translator(parseErrorMessage(e)));
            }

        }).finally(() => {
            this.setState({ submitting: false });
        })
    }
    onClickLoginRegisterForm = (e, register) => {
        e.preventDefault();
        const {applicationContext} = this.props;
        applicationContext.handleChangePopupTitle(register?"Create new account":"Log In")
        this.setState({
            register
        }, () => {

        })
    }
    render() {
        const {applicationContext} = this.props;
        let validationSchema = {};
        if(!this.state.register){
            validationSchema = {
                email: Yup.string().required(applicationContext.translator("You must enter your ${path}")).email(applicationContext.translator("E-Mail Address format is invalid.")).label(applicationContext.translator("E-Mail Address")),
                password: Yup.string().required(applicationContext.translator("You must enter your ${path}")).label(applicationContext.translator("Password")).min(8, applicationContext.translator("Password must be minimum 8 characters"))
            };
        }else{
            validationSchema = {
                email: Yup.string().required(applicationContext.translator("You must enter your ${path}")).email(applicationContext.translator("E-Mail Address format is invalid.")).label(applicationContext.translator("E-Mail Address")),
                first_name: Yup.string().required(applicationContext.translator("You must enter your ${path}")).min(3, applicationContext.translator("${path} must be minimum 3 characters")).label(applicationContext.translator("First name")),
                last_name: Yup.string().required(applicationContext.translator("You must enter your ${path}")).min(3, applicationContext.translator("${path} must be minimum 3 characters")).label(applicationContext.translator("Last name")),
                year_of_birth: Yup.string().required(applicationContext.translator("You must enter your ${path}")).label(applicationContext.translator("Year of birth")),
                month_of_birth: Yup.string().required(applicationContext.translator("You must enter your ${path}")).label(applicationContext.translator("Year of birth")),
                day_of_birth: Yup.string().required(applicationContext.translator("You must enter your ${path}")).label(applicationContext.translator("Year of birth")),
                gender: Yup.string().required(applicationContext.translator("You must enter your ${path}")).label(applicationContext.translator("Gender")),
                date_of_birth: Yup.string().test('birth_date', applicationContext.translator("You must select your date of birth"), (value, context) => {
                    return (context.parent.year_of_birth && context.parent.month_of_birth && context.parent.day_of_birth);
                }),
                password: Yup.string().required(applicationContext.translator("You must enter your ${path}"))
                    .test('password', applicationContext.translator("The password must contain at least 8 english characters and include upper and lower case letters, numbers and a symbol"), (value) => {
                        let check = passwordCheck(value);
                        return (check.message === "Strong")
                    })
                    .label(applicationContext.translator("Password")),
                password_confirmation: Yup.string().required(applicationContext.translator("You must enter your ${path}"))
                    .oneOf([Yup.ref('password'), null], applicationContext.translator('Passwords must match'))
                    .label(applicationContext.translator("Password Confirm"))
            };
        }
        return (
            <Formik
                innerRef={(ref) => this.refForm = ref}
                enableReinitialize={true}
                initialValues={this.state.forms[this.state.register?"register":"login"]}
                validationSchema={Yup.object().shape(validationSchema)}
                onSubmit={this.state.register?this.handleRegisterSubmit:this.handleLoginSubmit}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue
                  }) =>
                    (
                        this.state.register?(
                        this.state.registerEmailVerify?(
                            <EmailVerificationSuccessfully>
                                <FontAwesomeIcon icon={"check-circle"} /><br/>
                                {applicationContext.translator("Your Email has been subscribed successfully.")}
                                <br/>
                                {applicationContext.translator("An email has been sent to your inbox")}
                            </EmailVerificationSuccessfully>
                        ):(
                        <Form onSubmit={handleSubmit} style={{ direction: applicationContext.translator("direction") }}>
                            <Col md={12}>
                                <InputBox type="text"
                                          name={"email"}
                                          onChange={handleChange}
                                          placeholder={applicationContext.translator("E-Mail Address")}
                                          value={values.email}
                                          save={"off"}
                                          focus={true}
                                          errors={errors}
                                          touched={touched}
                                          dark={true}
                                />
                            </Col>
                            <Row>
                                <Col md={6}>
                                    <InputBox type="text"
                                              name={"first_name"}
                                              onChange={handleChange}
                                              placeholder={applicationContext.translator("First name")}
                                              value={values.first_name}
                                              save={"off"}
                                              focus={true}
                                              errors={errors}
                                              touched={touched}
                                              dark={true}
                                    />
                                </Col>
                                <Col md={6}>
                                    <InputBox type="text"
                                              name={"last_name"}
                                              onChange={handleChange}
                                              placeholder={applicationContext.translator("Last name")}
                                              value={values.last_name}
                                              save={"off"}
                                              focus={true}
                                              errors={errors}
                                              touched={touched}
                                              dark={true}
                                    />
                                </Col>
                            </Row>

                            <Form.Group>
                                <Flex>
                                    <Dropdown
                                        name={"year_of_birth"}
                                        onChange={handleChange}
                                        value={values.year_of_birth}
                                        invalid={errors && errors['date_of_birth']}
                                        noInvalidIcon={true}
                                        boxClassName={"dropdown year-dropdown"}
                                        padding={"0 .4em 0 0"}
                                        margin={"0"}
                                    >
                                        <option value={""}>{applicationContext.translator("Year of birth")}</option>
                                        {yearRange(new Date().getFullYear() - 90, new Date().getFullYear() - 18).map((year, index) => {
                                            return <option key={index} value={year} >{year}</option>
                                        })}
                                    </Dropdown>
                                    <Dropdown
                                        name={"month_of_birth"}
                                        onChange={handleChange}
                                        value={values.month_of_birth}
                                        invalid={errors && errors['date_of_birth']}
                                        noInvalidIcon={true}
                                        boxClassName={"dropdown month-dropdown"}
                                        padding={"0 .2em"}
                                        margin={"0"}
                                    >
                                        <option value={""}>{applicationContext.translator("Month of birth")}</option>
                                        <option value={1} >{applicationContext.translator("January")}</option>
                                        <option value={2} >{applicationContext.translator("February")}</option>
                                        <option value={3} >{applicationContext.translator("March")}</option>
                                        <option value={4} >{applicationContext.translator("April")}</option>
                                        <option value={5} >{applicationContext.translator("May")}</option>
                                        <option value={6} >{applicationContext.translator("June")}</option>
                                        <option value={7} >{applicationContext.translator("July")}</option>
                                        <option value={8} >{applicationContext.translator("August")}</option>
                                        <option value={9} >{applicationContext.translator("September")}</option>
                                        <option value={10} >{applicationContext.translator("October")}</option>
                                        <option value={11} >{applicationContext.translator("November")}</option>
                                        <option value={12} >{applicationContext.translator("December")}</option>
                                    </Dropdown>
                                    <Dropdown
                                        name={"day_of_birth"}
                                        onChange={handleChange}
                                        value={values.day_of_birth}
                                        invalid={errors && errors['date_of_birth']}
                                        noInvalidIcon={true}
                                        boxClassName={"dropdown day-dropdown"}
                                        padding={"0 0 0 .4em"}
                                        margin={"0"}
                                    >
                                        <option value={""}>{applicationContext.translator("Day of birth")}</option>
                                        {dayRange(values.month_of_birth, applicationContext.state.locale).map((day, index) => {
                                            return <option key={index} value={day} >{day}</option>
                                        })}
                                    </Dropdown>
                                </Flex>
                                {errors && errors['date_of_birth'] && <InputError hidden={errors['date_of_birth'] === undefined && true}>{errors['date_of_birth']}</InputError>}
                            </Form.Group>
                            <Form.Group className={"mt-3"}>
                                <Dropdown
                                    name={"gender"}
                                    onChange={handleChange}
                                    value={values.gender}
                                    invalid={touched.gender && errors.gender}
                                    noInvalidIcon={true}
                                    boxClassName={"dropdown day-dropdown"}
                                    padding={"0 0 0 0"}
                                    margin={"0"}
                                >
                                    <option value={""}>{applicationContext.translator("Gender")}</option>
                                    <option value={"men"} >{applicationContext.translator("Male")}</option>
                                    <option value={"women"} >{applicationContext.translator("Female")}</option>
                                </Dropdown>
                            </Form.Group>
                            <hr style={{ marginTop: "1em" }}/>
                            <Row>
                                <Col md={6}>
                                    <InputBox type="password"
                                              name={"password"}
                                              onChange={handleChange}
                                              placeholder={applicationContext.translator("Password")}
                                              value={values.password}
                                              save={"off"}
                                              errors={errors}
                                              touched={touched}
                                              strength={true}
                                              onCallbackStrength={(passwordHelp) => {
                                                  this.setState({
                                                      passwordHelp
                                                  })
                                              }}
                                    />
                                </Col>
                                <Col md={6}>
                                    <InputBox type="password"
                                              name={"password_confirmation"}
                                              onChange={handleChange}
                                              placeholder={applicationContext.translator("Password Confirm")}
                                              value={values.password_confirmation}
                                              save={"off"}
                                              errors={errors}
                                              touched={touched}
                                    />
                                </Col>
                            </Row>

                            {values.password && <ProfilePasswordHelp>
                                {this.state.passwordHelp}
                            </ProfilePasswordHelp>}
                            <Form.Group className={"text-center mt-3"}>
                                <Button color={"primary"} fullWidth={true} size={3} submitting={this.state.submitting} radius={10} className={"btn btn-account"} type={"submit"}>{applicationContext.translator("Register")}</Button>
                                <OrText>{applicationContext.translator("You have account?")} <NavLink to={"#"} onClick={(e) => this.onClickLoginRegisterForm(e, false)}>{applicationContext.translator("Login now")}</NavLink></OrText>

                            </Form.Group>
                        </Form>)
                        ):(
                        <Form onSubmit={handleSubmit} style={{ direction: applicationContext.translator("direction") }}>
                            <FormTitle>{applicationContext.translator("Enter username and password.")}</FormTitle>
                            <InputBox type="text"
                                      name={"email"}
                                      onChange={handleChange}
                                      placeholder={applicationContext.translator("E-Mail Address")}
                                      value={values.email}
                                      save={"off"}
                                      focus={true}
                                      errors={errors}
                                      touched={touched}
                                      dark={true}
                            />
                            <InputBox type={this.state.showPassword?"text":"password"}
                                      name={"password"}
                                      onChange={handleChange}
                                      placeholder={applicationContext.translator("Password")}
                                      value={values.password}
                                      save={"off"}
                                      errors={errors}
                                      touched={touched}
                                      dark={true}
                            />
                            <Form.Group className={"text-center"}>
                                <Button color={"primary"} fullWidth={true} size={3} submitting={this.state.submitting} radius={10} className={"btn btn-account"} type={"submit"}>{applicationContext.translator("Log In")}</Button>
                                <OrText>{applicationContext.translator("You don't have account?")} <NavLink to={"#"} onClick={(e) => this.onClickLoginRegisterForm(e, true)}>{applicationContext.translator("Create new account")}</NavLink></OrText>

                            </Form.Group>
                        </Form>)
                    )}
            </Formik>
        );
    }
}
LoginForm.propTypes = {
    onCallback: PropTypes.func.isRequired,
    register: PropTypes.bool
}
export default withAlert()(withApplicationContext(withRouter(withAccountContext(LoginForm))));