import styled from "styled-components";
import {Form} from 'react-bootstrap';

export const LoginContainer = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 5em auto;
`
export const BlockText = styled(Form.Text)`
  font-size: 1em;
  color: #333;
  & a{
    display: inline;
    color: var(--color-secondary);
    margin: 0 .3em;
  }
`
export const FormTitle = styled.h5`
  margin-bottom: 1.5em;
  font-size: 1.2em;
  font-weight: 500;
  color: #444;
  text-align: center;
`

export const SortableListContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin: .5em 0;
`
export const SortableItemContainer = styled.li`
  
`
export const ListContainer = styled.div`
  position: sticky;
  top: 0;
`