import styled from "styled-components";
import {Col, Container} from 'react-bootstrap';
export const MainContainer = styled.div`
  position: relative;
`;
export const HomeContainer = styled(Container)`
  min-height: calc(100vh - (300px + 2em) - 110px);
  transition: all ease-in-out .3s;
  padding-top: 2em;
  
`;
export const SpinnerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2em;
`
export const HomeWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
export const HomeMusicVideos = styled.div`
  width: 100%;
  flex: 1;
`
export const HomeVoteList = styled.div`
  width: 380px;
  padding: 0.5em 1em;
  background: rgb(0, 0, 0);
  border-radius: 10px;
  margin-left: 2em;
  @media screen and (max-width: 700px) {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    display: none;
    ${(props) => props.show && `
        display: block;
    `}
  }
  
`