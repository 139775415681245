import React, {Component} from 'react';
import {
    FooterCopyrightContainer,
    CopyrightText
} from "./style.jsx";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import {Container} from "react-bootstrap";
class Footer extends Component {

    render() {
        const {applicationContext} = this.props;

        return (
            <React.Fragment>
                <FooterCopyrightContainer>
                    <Container>
                        <CopyrightText>
                            PMC TOP20, a trademark and product of PMC GROUP,2003 © 2023, All rights reserved.
                        </CopyrightText>
                    </Container>
                </FooterCopyrightContainer>

            </React.Fragment>
        );
    }
}
Footer.propTypes = {

}
export default withApplicationContext(Footer);