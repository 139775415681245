import React, {Component} from 'react';
import {withApplicationContext} from "../../contexts/ApplicationContext"
import {Col, Row, Spinner} from 'react-bootstrap';
import {withAccountContext} from "../../contexts/AccountContext";
import MusicVideo from "../../components/ui/MusicVideo";
import {HomeContainer, SpinnerContainer, Title, Wrapper} from "./style";
import VotingPopup from "../../components/ui/VotingPopup";
import CommonService from "../../services/CommonService";
import {toDate} from "../../helpers";
class Top20 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            more: false,
            finish: false,
            page: 1,
            data: []
        }

    }


    componentDidMount = async () => {
        await this.handleScroll();
        this.setState({
            loading: false
        })
    }

    handleScroll = async () => {
        let {data, page} = this.state;
        await CommonService.fetchTop20Data(page).then((dataResult) => {
            if(dataResult.data.length === 0){
                this.setState({
                    more: false,
                    finish: true
                })
            }else{
                data = data.concat(dataResult.data);
                this.setState({
                    data,
                    page: page + 1,
                    more: false
                })
            }


        });
    }
    render() {
        const {applicationContext} = this.props;
        return (
            <React.Fragment>
                <HomeContainer>
                    {this.state.loading && this.state.data.length < 20?(
                        <SpinnerContainer>
                            <Spinner animation="border" role="status" variant={"primary"}>
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </SpinnerContainer>
                    ):(
                        <React.Fragment>
                            <Title><span>TOP20 - {toDate(this.state.data[0].date, "en")}</span></Title>
                            {this.state.data.length > 0 &&
                                <Wrapper>
                                    <Col md={3}>
                                        <MusicVideo data={this.state.data[0]} disableButton={true} md={12}/>
                                        <MusicVideo data={this.state.data[1]} disableButton={true} md={12}/>
                                        <MusicVideo data={this.state.data[2]} disableButton={true} md={12}/>
                                        <MusicVideo data={this.state.data[3]} disableButton={true} md={12}/>
                                        <MusicVideo data={this.state.data[4]} disableButton={true} md={12}/>
                                    </Col>
                                    <Col md={3}>
                                        <MusicVideo data={this.state.data[5]} disableButton={true} md={12}/>
                                        <MusicVideo data={this.state.data[6]} disableButton={true} md={12}/>
                                        <MusicVideo data={this.state.data[7]} disableButton={true} md={12}/>
                                        <MusicVideo data={this.state.data[8]} disableButton={true} md={12}/>
                                        <MusicVideo data={this.state.data[9]} disableButton={true} md={12}/>
                                    </Col>
                                    <Col md={3}>
                                        <MusicVideo data={this.state.data[10]} disableButton={true} md={12}/>
                                        <MusicVideo data={this.state.data[11]} disableButton={true} md={12}/>
                                        <MusicVideo data={this.state.data[12]} disableButton={true} md={12}/>
                                        <MusicVideo data={this.state.data[13]} disableButton={true} md={12}/>
                                        <MusicVideo data={this.state.data[14]} disableButton={true} md={12}/>
                                    </Col>
                                    <Col md={3}>
                                        <MusicVideo data={this.state.data[15]} disableButton={true} md={12}/>
                                        <MusicVideo data={this.state.data[16]} disableButton={true} md={12}/>
                                        <MusicVideo data={this.state.data[17]} disableButton={true} md={12}/>
                                        <MusicVideo data={this.state.data[18]} disableButton={true} md={12}/>
                                        <MusicVideo data={this.state.data[19]} disableButton={true} md={12}/>
                                    </Col>

                                </Wrapper>
                            }

                            {this.state.more && <SpinnerContainer>
                                <Spinner animation="border" size={"sm"} role="status" variant={"primary"}>
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </SpinnerContainer>}
                        </React.Fragment>
                    )}
                </HomeContainer>
            </React.Fragment>

        );
    }
}

export default withApplicationContext(withAccountContext(Top20));