import styled from "styled-components";
import RichTextEditor from "react-rte/lib/RichTextEditor";
import {Form} from "react-bootstrap";

export const Container = styled.div`
  ${(props) => props.margin && `
    margin: 1em 0;
  `};
  & .html-editor-container{
    color: var(--form-element-text-color);
    background: var(--form-element-background);
    border-color: ${(props) => props.error?"#dc3545":"var(--form-element-border)"};
    border-radius: var(--form-element-border-radius);
    width: 100%;
    min-height: 400px;
  }
  & .html-editor{
    width: 100%;
    padding: 0 1em;
  }
  & .html-editor-toolbar{
    border-color: var(--form-element-border);
    & select{
      background: #fff;
      color: #333;
      border: 1px solid var(--form-element-border);
      padding: 0 10px;
    }
    & button{
      background: #fff;
      border-color: var(--color-popup-border);
    }
  }
  @media screen and (max-width: 700px) {
    display: block;
    text-align: center;
  }
`;

export const InputError = styled(Form.Text)`
    color: #dc3545;
    margin: 0em 0.3em;
    ${(props) => props.hidden && `
        display: none;
    `}
    ${(props) => props.line && `
        padding-right: 100px;
    `}
    ${(props) => props.hide && `
        visibility: hidden;
    `}
    

`