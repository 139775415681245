import React, {Component} from 'react';
import {Container, InputError} from "./style.jsx";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import PropTypes from 'prop-types';
import RichTextEditor from 'react-rte';
class HtmlEditor extends Component {
    static propTypes = {
        onChange: PropTypes.func
    };

    state = {
        value: RichTextEditor.createEmptyValue()
    }
    onChange = (value) => {
        this.setState({value});
        if (this.props.onChange) {
            this.props.onChange(
                (value.toString('html') === "<p><br></p>")?"":value.toString('html')
            );
        }
    };


    render() {
        const {name, margin, touched, errors} = this.props;
        const toolbarConfig = {
            display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS', 'BLOCK_ALIGNMENT_BUTTONS'],
            INLINE_STYLE_BUTTONS: [
                {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
                {label: 'Italic', style: 'ITALIC'},
                {label: 'Underline', style: 'UNDERLINE'}
            ],
            BLOCK_ALIGNMENT_BUTTONS: [
                {label: 'Align Left', style: 'ALIGN_LEFT'},
                {label: 'Align Center', style: 'ALIGN_CENTER'},
                {label: 'Align Right', style: 'ALIGN_RIGHT'},
                {label: 'Align Justify', style: 'ALIGN_JUSTIFY'},
            ],
            BLOCK_TYPE_DROPDOWN: [
                {label: 'Normal', style: 'unstyled'},
                {label: 'Heading Large', style: 'header-one'},
                {label: 'Heading Medium', style: 'header-two'},
                {label: 'Heading Small', style: 'header-three'}
            ],
            BLOCK_TYPE_BUTTONS: [
                {label: 'UL', style: 'unordered-list-item'},
                {label: 'OL', style: 'ordered-list-item'}
            ]
        };
        return (
            <Container margin={margin} error={touched && touched[name] && errors[name]}>
                <RichTextEditor
                    {...this.props}
                    value={this.state.value}
                    onChange={this.onChange}
                    toolbarConfig={toolbarConfig}
                    toolbarClassName="html-editor-toolbar"
                    editorClassName="html-editor"
                    className={"html-editor-container"}
                />
                {touched && touched[name] && <InputError hidden={errors[name] === undefined && true}>{errors[name]}</InputError>}
            </Container>
        );
    }
}
HtmlEditor.propTypes = {
    name: PropTypes.string,
    margin: PropTypes.bool,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string
}
export default withApplicationContext(HtmlEditor);