import axios from "axios";
import AdminTokenService from "./AdminTokenService";
import {ROUTE_ADMIN_LOGIN} from "../routes/routes";

const instance = axios.create({
    baseURL: process.env.REACT_APP_BACK_END_WEBSERVICE_API,
    headers: {
        "Content-Type": "application/json",
    },
});
instance.interceptors.request.use(
    (config) => {
        const token = AdminTokenService.getLocalAccessToken();
        if (token) {
            config.headers["Authorization"] = 'Bearer ' + token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        const originalConfig = err.config;
        if (originalConfig.url !== "/oauth/token" && err.response) {
            // Access Token was expired
            if (err.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;
                try {
                    AdminTokenService.removeUser();
                    return Promise.reject("Authorization Failed.");


                } catch (_error) {
                    console.log(_error.response)
                }
                return instance(originalConfig);
            }


        }
        if (originalConfig.url === "/oauth/token" && err.response) {
            if (err.response.status === 401){
                AdminTokenService.removeUser();
                window.location.href = ROUTE_ADMIN_LOGIN;
            }
        }

        return Promise.reject(err);
    }
);

export default instance;