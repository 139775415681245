import React from 'react';
import SortableItem from './SortableItem';
import { SortableContainer } from 'react-sortable-hoc';
import {SortableListContainer} from "./style";

const SortableList = (props) => {
    return (
        <SortableListContainer>
            {props.items.map((value, index) => <SortableItem key={`item-${index}`} isVoted={props.isVoted} disabled={props.isVoted} index={index} value={value} number={index + 1} />)}
        </SortableListContainer>
    );
}

export default SortableContainer(SortableList);