import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {withAlert} from "react-alert";
import withRouter from "../../../contexts/withRouter";
import {withAdminContext} from "../../../contexts/AdminContext";
import {OverviewContainer, OverviewRow, OverviewTitle, OverviewValue, OverviewValueWrapper, Sign} from "./style";
import LoggedIn from "../LoggedIn";
import withAdminAuth from "../../../contexts/withAdminAuth";
import Card from "../../../components/ui/Card";
import DetailsTable from "../../../components/ui/DetailsTable";
import AdminService from "../../../services/AdminService";
import {numberWithCommas} from "../../../helpers";

class Overview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loadingData: true,
            data: [],
            search: {

            },
            sort: {
                key: 'id',
                dir: 'desc'
            },
        }

    }
    componentDidMount = async () => {
        let overview = await AdminService.overview().then((data) => {
            this.setState({
                overview: data,
                loading: false
            })
        })
    }


    render() {
        const {applicationContext} = this.props;
        return (
            <LoggedIn loading={this.state.loading}>
                {!this.state.loading && <OverviewContainer>
                    <OverviewRow>
                        <OverviewTitle>{applicationContext.translator("Total Members")}</OverviewTitle>
                        <OverviewValue>{numberWithCommas(this.state.overview['members'], false, ",")}</OverviewValue>
                    </OverviewRow>
                    <OverviewRow>
                        <OverviewTitle>{applicationContext.translator("Total Cash-in")}</OverviewTitle>
                        <OverviewValue>
                            {numberWithCommas(this.state.overview['deposit'])}.00
                            <Sign>USD</Sign>
                        </OverviewValue>
                    </OverviewRow>
                    <OverviewRow>
                        <OverviewTitle>{applicationContext.translator("Total Tickets")}</OverviewTitle>
                        <OverviewValue>{numberWithCommas(this.state.overview['tickets'], false, ",")}</OverviewValue>
                    </OverviewRow>
                    <OverviewRow>
                        <OverviewTitle>{applicationContext.translator("2 Matched Numbers")}</OverviewTitle>
                        <OverviewValue>{numberWithCommas(this.state.overview['won_2'], false, ",")}</OverviewValue>
                    </OverviewRow>
                    <OverviewRow>
                        <OverviewTitle>{applicationContext.translator("3 Matched Numbers")}</OverviewTitle>
                        <OverviewValue>{numberWithCommas(this.state.overview['won_3'], false, ",")}</OverviewValue>
                    </OverviewRow>
                    <OverviewRow>
                        <OverviewTitle>{applicationContext.translator("4 Matched Numbers")}</OverviewTitle>
                        <OverviewValue>{numberWithCommas(this.state.overview['won_4'], false, ",")}</OverviewValue>
                    </OverviewRow>
                    <OverviewRow>
                        <OverviewTitle>{applicationContext.translator("5 Matched Numbers")}</OverviewTitle>
                        <OverviewValue>{numberWithCommas(this.state.overview['won_5'], false, ",")}</OverviewValue>
                    </OverviewRow>
                    <OverviewRow>
                        <OverviewTitle>{applicationContext.translator("5+ Reserved")}</OverviewTitle>
                        <OverviewValue>{numberWithCommas(this.state.overview['won_6'], false, ",")}</OverviewValue>
                    </OverviewRow>
                    <OverviewRow>
                        <OverviewTitle>{applicationContext.translator("6 Matched Numbers")}</OverviewTitle>
                        <OverviewValue>{numberWithCommas(this.state.overview['won_100'], false, ",")}</OverviewValue>
                    </OverviewRow>
                    <OverviewRow>
                        <OverviewTitle>{applicationContext.translator("Total not used balance")}</OverviewTitle>
                        <OverviewValue>
                            {numberWithCommas(this.state.overview['not_used_balance'])}.00
                            <Sign>USD</Sign>
                        </OverviewValue>
                    </OverviewRow>
                </OverviewContainer>}

            </LoggedIn>
        );
    }
}

export default withAlert()(withApplicationContext(withRouter(withAdminContext(withAdminAuth(Overview)))));