import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {withAlert} from "react-alert";
import withRouter from "../../../contexts/withRouter";
import {withAdminContext} from "../../../contexts/AdminContext";
import LoggedIn from "../LoggedIn";
import withAdminAuth from "../../../contexts/withAdminAuth";
import EnterMasterPassword from "../EnterMasterPassword";
import * as Yup from "yup";
import Card from "../../../components/ui/Card";
import {Form} from "react-bootstrap";
import InputBox from "../../../components/ui/InputBox/InputBox";
import Button from "../../../components/ui/Button";
import {Formik} from "formik";
import AdminService from "../../../services/AdminService";
import HtmlEditor from "../../../components/ui/HtmlEditor";

class SendEmail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loggedIn: false,
            password: "",
            form: {
                subject: "",
                content: ""
            }
        }

    }
    componentDidMount = async () => {

    }

    onSubmit = async (values, actions) => {
        const {applicationContext, alert, onCallback} = this.props;

        this.setState({ submitting: true });
        await AdminService.transfer({
            ...values,
            password: this.state.password
        }).then((data) => {
            this.setState({
                amount: "",
                account_id: ""
            })
            actions.resetForm();
            alert.success(applicationContext.translator("The user account has been charged successfully."));

        }).catch((e) => {
            if(e.response.data.hasOwnProperty('errors') && Object.keys(e.response.data.errors).length > 0){
                Object.keys(e.response.data.errors).map((field) => {
                    actions.setFieldError(field, applicationContext.translator(e.response.data.errors[field][0]))
                })
            }else{
                alert.error(applicationContext.translator(e.response.data.message));
            }

        }).finally(() => {
            this.setState({ submitting: false });
        });
    }
    render() {
        const {applicationContext} = this.props;
        return (
            <LoggedIn loading={this.state.loading}>
                <Formik
                    innerRef={(ref) => this.refForm = ref}
                    initialValues={this.state.form}
                    validationSchema={Yup.object().shape({
                        subject: Yup.string().required(applicationContext.translator("You must enter your ${path}")).label(applicationContext.translator("Subject")),
                        content: Yup.string().required(applicationContext.translator("You must enter your ${path}")).label(applicationContext.translator("Content")),
                    })}
                    onSubmit={this.onSubmit}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleSubmit,
                          isSubmitting,
                          setFieldValue
                      }) =>
                        (
                            <Card>
                                <Form onSubmit={handleSubmit} style={{ direction: applicationContext.translator("direction") }}>
                                    <InputBox type="text"
                                              name={"subject"}
                                              onChange={handleChange}
                                              placeholder={applicationContext.translator("Subject")}
                                              value={values.subject}
                                              save={"off"}
                                              focus={true}
                                              errors={errors}
                                              touched={touched}
                                    />
                                    <HtmlEditor
                                        name={"content"}
                                        placeholder={applicationContext.translator("Write your message")}
                                        onChange={(value) => setFieldValue('content', value)}
                                        value={values.content}
                                        errors={errors}
                                        touched={touched}
                                        margin={true}
                                    />
                                    <Form.Group className={"text-center"}>
                                        <Button color={"primary"} fullWidth={true} size={3} submitting={this.state.submitting} type={"submit"}>
                                            {applicationContext.translator("Send")}
                                        </Button>
                                    </Form.Group>


                                </Form>
                            </Card>
                        )}
                </Formik>
            </LoggedIn>
        );
    }
}

export default withAlert()(withApplicationContext(withRouter(withAdminContext(withAdminAuth(SendEmail)))));