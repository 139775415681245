import React, {Component} from 'react';
import {Container, Wrapper, Number, MusicVideoInfo, MusicVideoName, MusicVideoSinger, VoteButtonContainer, VoteButton, DeleteButton} from "./style.jsx";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {withAccountContext} from "../../../contexts/AccountContext";
import {withAdminContext} from "../../../contexts/AdminContext";
class AdminMusicVideo extends Component {
    onClickVote = (e) => {
        const {data, onAddToList} = this.props;
        onAddToList(data['id'], data);
    }

    renderButton = () => {
        const {data, handleCheckExistsId, onDeleteMusicFromList} = this.props;
        if(handleCheckExistsId(data['id']))
            return <VoteButton variant={"success"} onClick={(e) => onDeleteMusicFromList(data['id'])}><FontAwesomeIcon icon={"trash"}/> Delete from top list</VoteButton>

        return <VoteButton variant={"vote"} onClick={this.onClickVote}><FontAwesomeIcon icon={"plus"}/> Add to top list</VoteButton>
    }
    render() {
        const {adminContext, data, md, deleteFromVote, disableButton, onDeleteMusicFromList, showScore} = this.props;

        return (
            <Container admin={true} md={md??(adminContext.state.popup?4:3)}>
                <Wrapper deleteFromVote={deleteFromVote} disableButton={disableButton} admin={true}>
                    <MusicVideoInfo>
                        <MusicVideoName admin={true}><span>{data.hasOwnProperty('rank')?data['rank']:data['number']}. {data['name']}</span> - {data['singer']}</MusicVideoName>
                        {showScore && <MusicVideoSinger>Score: <b>{data['total_score']}</b> / Votes: <b>{data['total_vote']}</b></MusicVideoSinger>}
                    </MusicVideoInfo>
                    {deleteFromVote && !adminContext.state.isVoted && <DeleteButton admin={true} variant={"danger"} onClick={(e) => onDeleteMusicFromList(data)}><FontAwesomeIcon icon={"trash"}/></DeleteButton>}
                </Wrapper>
                {(!deleteFromVote && !disableButton) && <VoteButtonContainer>
                    {this.renderButton()}
                </VoteButtonContainer>}
            </Container>
        );
    }
}
AdminMusicVideo.propTypes = {
    data: PropTypes.object,
    md: PropTypes.number,
    deleteFromVote: PropTypes.bool,
    handleCheckExistsId: PropTypes.func,
    onDeleteMusicFromList: PropTypes.func,
    handleClearList: PropTypes.func,
    onAddToList: PropTypes.func,
    showScore: PropTypes.bool
}
export default withApplicationContext(withAdminContext(AdminMusicVideo));