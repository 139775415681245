import styled from "styled-components";
import {Col, Button} from "react-bootstrap";
export const Container = styled(Col)`
  margin: .5em 0;
  ${(props) => props.admin && `
    margin: 0.2em 0px;
  `}
  
`;
export const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .5em 1em 0.5em 1em;
  background: var(--song-color-background);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  
  ${(props) => props.deleteFromVote && `
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    cursor: grabbing;
  `}
  ${(props) => props.disableButton && `
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 1em;
  `}
  ${(props) => props.admin && `
    padding: .3em .5em;
    border-radius: 5px;
  `}
  
  
  color: #fff;
`;
export const Number = styled.div`
  min-width: 50px;
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background: var(--color-background);
  color: #fff;
  font-size: 1.2em;
  font-weight: 700;
  margin-right: 0.8em;
  ${(props) => props.deleteFromVote && `
    min-width: 35px;
    min-height: 35px;
    font-size: 1em;
  `}
`
export const MusicVideoInfo = styled.div`
  width: 100%;
`
export const MusicVideoName = styled.div`
  color: var(--color-primary);
  font-weight: 600;
  font-size: .9em;
  ${(props) => props.admin && `
    font-weight: 500;
    font-size: .85em;
  `}
  & span{
    color: #fff;
  }
`
export const MusicVideoSinger = styled.div`
  font-size: .8em;
`
export const VoteButtonContainer = styled.div`
  width: 100%;
  background: var(--color-secondary);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: #fff;
`
export const VoteButton = styled(Button)`
  width: 100%;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  font-size: .9em;
  & svg{
    font-size: 17px;
    margin: 0 0.3em;
  }
`
export const DeleteButton = styled(Button)`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: .3em;
  ${(props) => props.admin && `
    width: 20px;
    height: 20px;
    font-size: 10px;
    background: transparent;
    border: 0;
  `}
`