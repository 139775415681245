import styled from "styled-components";
import {Button} from "react-bootstrap";

export const Container = styled.div`
    width: 100%;
  max-width: 100%;
  position: sticky;
  top: 0px;
  bottom: 0;
  right: -400px;
  left: 100%;
  opacity: 0;
  background: #000;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 2px #000;
  transition: all linear .3s;
  z-index: 999999;
  
  ${(props) => props.dir && `
    direction: ${props.dir};
  `};
  
`;
export const PopupContainer = styled.div`
  width: 100%;
  height: 100%;
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 1em 1em 0 1em;
  justify-content: space-between;
  direction: ltr;
`
export const Footer = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid var(--color-background);
  padding: 0;
  justify-content: flex-start;
  position: relative;
  ${(props) => props.disabled && `
    & *{
      pointer-events: none; 
      opacity: .7;
    }
  `}
`
export const Title = styled.div`
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`
export const Body = styled.div`
  padding: 1em;
  height: calc(100% - 100px);
`
export const SelectedText = styled.p`
  font-weight: 600;
  font-size: .9em;
  & span{
    color: rgb(52 209 59);
  }
`
export const Icon = styled.div`
  font-size: 20px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover{
    color: var(--color-primary);
    transition: all ease-in-out .3s;
  }
`
export const SortableListContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`
export const SortableItemContainer = styled.li`
  
`
export const SendButton = styled(Button)`
  text-transform: uppercase;
  width: 100%;
  padding: .5em;
`
export const SubmittedSuccessfully = styled.div`
  color: rgb(52 209 59);
  text-align: center;
  font-size: .9em;
  font-weight: 500;
  line-height: 1.7em;
  margin: 2em 0 0 0;
  & svg{
    font-size: 2em;
    margin-bottom: 0.3em;
  }
`
export const ErrorText = styled.div`
  color: #d50f0a;
  text-align: center;
  font-size: .9em;
  font-weight: 500;
  line-height: 1.7em;
  margin: 1em 0 0 0;
  & svg{
    font-size: 2em;
    margin-bottom: 0.3em;
  }
`
export const Description = styled.p`
  font-size: .9em;
  padding: 1em 0 0 0;
  color: #d50f0a;
  text-align: center;
`
export const SelectedNumber = styled.div`
  background: #d50f0a;
  color: #fff;
  border-radius: 5px;
  padding: 0.3em 0.9em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: .9em;
  font-weight: bold;
`

export const PopupCloseIcon = styled.div`
  position: relative;
  display: none;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  background: #242424;
  @media screen and (max-width: 700px) {
    display: flex;
  }
`