import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {withAlert} from "react-alert";
import withRouter from "../../../contexts/withRouter";
import {Container, Wrapper, Card} from "./style";
import {withAdminContext} from "../../../contexts/AdminContext";
import * as Yup from "yup";
import {Form} from "react-bootstrap";
import InputBox from "../../../components/ui/InputBox/InputBox";
import Button from "../../../components/ui/Button";
import {Formik} from "formik";
import Logo from "../../../components/ui/Logo";
import AuthService from "../../../services/AuthService";
import {parseErrorMessage} from "../../../helpers";
import AdminTokenService from "../../../services/AdminTokenService";
import AdminService from "../../../services/AdminService";
import StorageService from "../../../services/StorageService";
import {ROUTE_ADMIN_SEARCH} from "../../../routes/routes";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submitting: false,
            email: "",
            password: ""
        }

    }
    componentDidMount() {

    }

    handleSubmit = async (values, actions) => {
        const {applicationContext, adminContext, alert, onCallback} = this.props;
        this.setState({ submitting: true });
        try {
            const token  = await AuthService.login(values.email, values.password, "panel.admin");
            AdminTokenService.updateLocalAccessToken(token);
            const userInfo = await AdminService.getUserBoard();
            adminContext.updateUserInfo(userInfo, () => {
                const {navigate} = this.props;
                let redirect = (StorageService.get('redirect'))??ROUTE_ADMIN_SEARCH;
                StorageService.remove('redirect');
                navigate(redirect)
            });
        } catch (err) {
            this.setState({ submitting: false });
            alert.error(applicationContext.translator(parseErrorMessage(err)));
        }
    }
    render() {
        const {applicationContext} = this.props;
        return (
            <Container>
                <Wrapper>
                    <Card>
                        <Formik
                            innerRef={(ref) => this.refForm = ref}
                            initialValues={this.state}
                            validationSchema={Yup.object().shape({
                                email: Yup.string().required(applicationContext.translator("You must enter your ${path}")).email(applicationContext.translator("E-Mail Address format is invalid.")).label(applicationContext.translator("E-Mail Address")),
                                password: Yup.string().required(applicationContext.translator("You must enter your ${path}")).label(applicationContext.translator("Password")).min(8, applicationContext.translator("Password must be minimum 8 characters"))
                            })}
                            onSubmit={this.handleSubmit}
                        >
                            {({
                                  values,
                                  errors,
                                  touched,
                                  handleChange,
                                  handleSubmit,
                                  isSubmitting,
                                  setFieldValue
                              }) =>
                                (
                                    <Form onSubmit={handleSubmit} style={{ direction: applicationContext.translator("direction") }}>
                                        <Logo marginBottom={"2em"}/>
                                        <InputBox type="text"
                                                  name={"email"}
                                                  onChange={handleChange}
                                                  placeholder={applicationContext.translator("E-Mail Address")}
                                                  value={values.email}
                                                  save={"off"}
                                                  focus={true}
                                                  errors={errors}
                                                  touched={touched}
                                        />
                                        <InputBox type={this.state.showPassword?"text":"password"}
                                                  name={"password"}
                                                  onChange={handleChange}
                                                  placeholder={applicationContext.translator("Password")}
                                                  value={values.password}
                                                  save={"off"}
                                                  errors={errors}
                                                  touched={touched}
                                        />
                                        <Form.Group className={"text-center"}>
                                            <Button color={"primary"} fullWidth={true} size={3} submitting={this.state.submitting} radius={10} className={"btn btn-account"} type={"submit"}>{applicationContext.translator("Log In")}</Button>
                                        </Form.Group>
                                    </Form>
                                )}
                        </Formik>
                    </Card>
                </Wrapper>
            </Container>
        );
    }
}

export default withAlert()(withApplicationContext(withRouter(withAdminContext(Login))));