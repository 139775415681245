import styled from "styled-components";

export const FooterMenuContainer = styled.div`
  width: 100%;
  background: #fff;
  border-top: 2px solid #ccc;
  border-bottom: 2px solid #ccc;
  margin-top: 7em;
  padding: 2em;
`;
export const FooterMenu = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 700px) {
    display: block;
    text-align: center;
  }
`
export const FooterMenuItem = styled.li`
  padding: 1em 2em;
  & a{
    color: #333;
    font-weight: 600;
    &:hover{
      color: var(--color-primary);
      transition: all ease-in-out 0.2s;
    }
  }
`
export const FooterCopyrightContainer = styled.div`
  width: 100%;
  text-align: center;
  border-top: 2px solid #343434;
  padding: 2em;
  background: #000;
  color: #fff;
`
export const ConditionText = styled.p`
  font-size: 1em;
  font-weight: 600;
  line-height: 1.7em;
  border-bottom: 2px solid #ccc;
  padding-bottom: 3em;
  & a{
    color: #333333;
    display: block;
    margin-top: 0.5em;
    &:hover{
      color: var(--color-primary);
      transition: all ease-in-out 0.2s;
    }
  }
`
export const CopyrightText = styled.p`
  font-size: 0.8em;
  line-height: 1.7em;
  direction: ltr;
`

export const SocialLink = styled.a`
  font-size: 24px;
`