import React, {Component} from 'react';
import PropTypes from "prop-types";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import withRouter from "../../../contexts/withRouter";
import {withAdminContext} from "../../../contexts/AdminContext";
import withAdminAuth from "../../../contexts/withAdminAuth";
import SearchTable from "../../../components/ui/SearchTable";

class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        }
    }
    componentDidMount = async () => {
        this.setState({
            loading: false
        })
    }
    render() {
        const {doFetchData} = this.props;
        return (
            <SearchTable
                loading={this.state.loading}
                search={[
                    {id: 'date', type: 'datepicker', label: "Date", defaultValue: this.props.data.date, enableDays: ["thursday"], onChange: true}
                ]}
                sort={{
                    field: 'num',
                    dir: 'asc'
                }}
                doFetchData={doFetchData}
            />
        );
    }
}
Search.propTypes = {
    data: PropTypes.any,
    doFetchData: PropTypes.func
}
export default withApplicationContext(withRouter(withAdminContext(withAdminAuth(Search))));