import styled from "styled-components";
import {Col, Container, Row} from 'react-bootstrap';
export const HomeContainer = styled(Container)`
  min-height: calc(100vh - (300px + 91px));
`;
export const SpinnerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2em;
`
export const Wrapper = styled(Row)`
  
`
export const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 2em;
  text-transform: uppercase;
  & span{
    background: var(--song-color-background);
    padding: 1em;
    border-radius: 10px;
    font-weight: 600;
    color: var(--color-primary);
  }
`