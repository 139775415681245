import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {withAlert} from "react-alert";
import withRouter from "../../../contexts/withRouter";
import {withAdminContext} from "../../../contexts/AdminContext";
import LoggedIn from "../LoggedIn";
import withAdminAuth from "../../../contexts/withAdminAuth";
import AdminService from "../../../services/AdminService";
import {accountId, msisdn, numberWithCommas, toDate} from "../../../helpers";
import DataList from "../../../components/ui/DataList/DataList";
import Search from "./Search";
import DetailsTable from "../../../components/ui/DetailsTable";

class Users extends Component {
    constructor(props) {
        super(props);
        const urlSearchParams = new URLSearchParams(props.location.search);
        this.state = {
            loading: true,
            loadingData: true,
            data: [],
            search: {
                keyword: urlSearchParams.get('uid')
            },
            sort: {
                key: 'id',
                dir: 'desc'
            },
        }

    }
    componentDidMount = async () => {
        await this.fetchData(1);

        this.setState({ loading: false })
    }

    fetchData = async (page, sort_field = 'id', sort_dir = 'desc', search = null, callback = null) => {
        this.setState({ loadingData: true })
        let data = await AdminService.users({
            page,
            limit: 10,
            role: 'user',
            sort_field,
            sort_dir,
            ...(search??this.state.search)
        });
        this.setState({
            loadingData: false,
            data: data,
            search: search??this.state.search,
            sort:{
                key: sort_field,
                dir: sort_dir
            }
        }, callback)
    }
    renderSearch = () => {
        return <Search data={this.state.search} doFetchData={this.fetchData}/>;
    }
    onUpdateSearch = (data) => {
        this.setState({
            search: data
        })
    }
    onViewUser = (row) => {
        const {applicationContext} = this.props;
        let data = [
            {title: "Account ID", value: accountId(row.id)},
            {title: "E-Mail Address", value: row.email},
            {title: "First name", value: row.first_name},
            {title: "Last name", value: row.last_name},
            {title: "Gender", value: row.gender},
            {title: "Date of birth", value: toDate(row.date_of_birth, applicationContext.state.locale)},
            {title: "Registered Time", value: toDate(row.created_at, applicationContext.state.locale, true)}
        ];
        if(row['blacklisted_at'])
            data.push({title: "Blacklisted Time", value: toDate(row['blacklisted_at'], applicationContext.state.locale, true)});
        return applicationContext.onOpenPopup("User Information", <DetailsTable data={data} />);
    }
    onActivateUser = async (row, callback) => {
        const {applicationContext, alert} = this.props;
        await AdminService.activate(row.id).then((data) => {
            callback(data)
        }).catch((e) => {
            Object.keys(e.response.data.errors).map((field) => {
                alert.error(applicationContext.translator(e.response.data.errors[field][0]))
            })
        })
    }
    onDeActivateUser = async (row, callback) => {
        const {applicationContext, alert} = this.props;
        await AdminService.deactivate(row.id).then((data) => {
            callback(data)
        }).catch((e) => {
            Object.keys(e.response.data.errors).map((field) => {
                alert.error(applicationContext.translator(e.response.data.errors[field][0]))
            })
        })
    }
    render() {
        const {adminContext} = this.props;
        return (
            <LoggedIn loading={this.state.loading}>
                <DataList
                    columns={[
                        {key: "id", label: "#", format: "number"},
                        {key: "id", label: "Account ID", render: accountId, dir: "ltr"},
                        {key: 'full_name', label: "Full name"},
                        {key: 'gender', label: "Gender"},
                        {key: 'email', label: "E-Mail Address"},
                        {key: "created_at", label: "Date", format: "date"},
                        {key: "", label: "Actions", actions: [
                            {label: "View", route: "#", onClick: this.onViewUser, submitting: true},
                            {label: "Activate", route: "#", onClick: this.onActivateUser, color: "#4caf50", hidden: ["blacklisted_at", "=", null]},
                            {label: "Deactivate", route: "#", onClick: this.onDeActivateUser, color: "#f44336", hidden: ["blacklisted_at", "<>", null]},
                        ]}
                    ]}
                    data={this.state.data}
                    action={this.fetchData}
                    search={this.renderSearch}
                    sort={this.state.sort}
                    loading={this.state.loadingData}
                />
            </LoggedIn>
        );
    }
}

export default withAlert()(withApplicationContext(withRouter(withAdminContext(withAdminAuth(Users)))));