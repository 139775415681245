import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {withAlert} from "react-alert";
import withRouter from "../../../contexts/withRouter";
import {withAdminContext} from "../../../contexts/AdminContext";
import LoggedIn from "../LoggedIn";
import withAdminAuth from "../../../contexts/withAdminAuth";
import * as Yup from "yup";
import Card from "../../../components/ui/Card";
import {Form} from "react-bootstrap";
import InputBox from "../../../components/ui/InputBox/InputBox";
import Button from "../../../components/ui/Button";
import {Formik} from "formik";
import AdminService from "../../../services/AdminService";

class AddMusicVideo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            updatingId: null,
            form: {
                code: "",
                name: "",
                singer: ""
            }
        }

    }
    componentDidMount = async () => {
        const {id} = this.props.params;
        if(id){
            await AdminService.musicVideo(id).then((data) => {
                this.setState({
                    updatingId: id,
                    form: {
                        code: data.code,
                        name: data.name,
                        singer: data.singer
                    },
                    loading: false
                })
            })

        }else{
            this.setState({ loading: false })
        }

    }
    handleError = (e, actions) => {
        const {applicationContext, alert} = this.props;
        if(e.response.data.hasOwnProperty('errors') && Object.keys(e.response.data.errors).length > 0){
            Object.keys(e.response.data.errors).map((field) => {
                actions.setFieldError(field, applicationContext.translator(e.response.data.errors[field][0]))
            })
        }else{
            alert.error(applicationContext.translator(e.response.data.message));
        }
    }
    onSubmit = async (values, actions) => {
        const {applicationContext, alert} = this.props;
        this.setState({ submitting: true });
        if(this.state.updatingId){
            await AdminService.updateMusicVideo(this.state.updatingId, values).then((data) => {
                alert.success(applicationContext.translator("Music video updated successfully"));
            }).catch((e) => {
                this.handleError(e, actions);
            }).finally(() => {
                this.setState({ submitting: false });
            });
        }else{
            await AdminService.addMusicVideo(values).then((data) => {
                alert.success(applicationContext.translator("Music video created successfully"));
                actions.resetForm();
            }).catch((e) => {
                this.handleError(e, actions);
            }).finally(() => {
                this.setState({ submitting: false });
            });
        }
    }

    render() {
        const {applicationContext} = this.props;
        return (
            <LoggedIn loading={this.state.loading}>
                <Formik
                    innerRef={(ref) => this.refForm = ref}
                    initialValues={this.state.form}
                    validationSchema={Yup.object().shape({
                        code: Yup.number().required(applicationContext.translator("You must enter your ${path}")).label(applicationContext.translator("Code")),
                        name: Yup.string().required(applicationContext.translator("You must enter your ${path}")).label(applicationContext.translator("Name")).min(3, applicationContext.translator("${path} must be minimum 3 characters")),
                        singer: Yup.string().required(applicationContext.translator("You must enter your ${path}")).label(applicationContext.translator("Singer")).min(3, applicationContext.translator("${path} must be minimum 3 characters")),
                    })}
                    onSubmit={this.onSubmit}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleSubmit,
                          isSubmitting,
                          setFieldValue
                      }) =>
                        (
                            <Card maxWidth={800} center={true} top={"2em"}>
                                <Form onSubmit={handleSubmit} style={{ direction: applicationContext.translator("direction") }}>
                                    <InputBox type={"text"}
                                              name={"code"}
                                              onChange={handleChange}
                                              placeholder={applicationContext.translator("Code")}
                                              value={values.code}
                                              save={"off"}
                                              errors={errors}
                                              touched={touched}
                                    />
                                    <InputBox type={"text"}
                                              name={"name"}
                                              onChange={handleChange}
                                              placeholder={applicationContext.translator("Name")}
                                              value={values.name}
                                              save={"off"}
                                              errors={errors}
                                              touched={touched}
                                    />
                                    <InputBox type={"text"}
                                              name={"singer"}
                                              onChange={handleChange}
                                              placeholder={applicationContext.translator("Singer")}
                                              value={values.singer}
                                              save={"off"}
                                              errors={errors}
                                              touched={touched}
                                    />
                                    <Form.Group className={"text-center"}>
                                        <Button color={"primary"} fullWidth={true} size={3} submitting={this.state.submitting} type={"submit"}>
                                            {applicationContext.translator("OK")}
                                        </Button>
                                    </Form.Group>


                                </Form>
                            </Card>
                        )}
                </Formik>
            </LoggedIn>
        );
    }
}

export default withAlert()(withApplicationContext(withRouter(withAdminContext(withAdminAuth(AddMusicVideo)))));