import styled from "styled-components";
import {Form, Row} from 'react-bootstrap';

export const Container = styled.div`
    width: 100%;
    max-width: 600px;
    margin: 4em auto;
  @media screen and (max-width: 700px) {
    display: block;
    margin: 0em auto;
  }
`
export const Table = styled.table`
  width: 100%;
`
export const TableRow = styled.tr`

`
export const TableHead = styled.th`
  padding: 0.7em;
  border: 1px solid rgb(47 57 82);
  background: rgb(47 57 82);
  ${(props) => props.header && `
    border-color: var(--color-primary);
    background: var(--color-primary);
  `}
  font-size: .9em;
  text-align: center;
  font-weight: bold;
`
export const TableCell = styled.td`
  padding: 0.7em;
  border: 1px solid rgb(47 57 82);
  font-size: .9em;
  ${(props) => props.center && `
    text-align: center;
  `}
  & span{
    color: var(--color-primary);
  }
`
export const Top20Container = styled.div`
  padding: 0 3em;
  position: relative;
  min-height: calc(100vh - 100px);
`
export const NavigateArrow = styled.div`
  height: 100%;
  width: 1.5em;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  cursor: pointer;
  transition: all ease-in-out .3s;
  &:hover{
    color: var(--color-primary);
  }
  ${(props) => props.right && `
    right: 0;
  `}
  ${(props) => props.left && `
    left: 0;
  `}
`