import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {withAlert} from "react-alert";
import withRouter from "../../../contexts/withRouter";
import {withAdminContext} from "../../../contexts/AdminContext";
import LoggedIn from "../LoggedIn";
import withAdminAuth from "../../../contexts/withAdminAuth";
import AdminService from "../../../services/AdminService";
import {HomeContainer, MainContainer, SpinnerContainer} from "../../Home/style";
import {Col, Row, Spinner} from "react-bootstrap";
import {arrayMoveImmutable} from "array-move";
import SortableList from "./SortableList";
import {SendButton, SubmittedSuccessfully} from "../../../components/ui/VotingPopup/style";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DataList from "../../../components/ui/DataList/DataList";
import CommonService from "../../../services/CommonService";
import {ListContainer} from "./style";
import {toDate} from "../../../helpers";

class Top20Votes extends Component {
    refContainer = React.createRef();
    constructor(props) {
        super(props);
        const urlSearchParams = new URLSearchParams(props.location.search);
        this.state = {
            loading: true,
            loadingData: true,
            data: [],
            topListId: props.params.id,
            topList: null,
            list: [],
            search: {
                code: "",
                singer: "",
                name: "",
                user_id: ""
            },
            sort: {
                key: 'total_score',
                dir: 'desc'
            },
            page: 1,
            addSubmitting: false,
            deleteSubmitting: false
        }

    }
    componentDidMount = async () => {
        await CommonService.fetchTop20Data(1).then((dataResult) => {
            AdminService.showTopList(this.props.params.id).then((topListResult) => {
                this.setState({
                    topList: topListResult,
                    list: dataResult.data,
                    loading: false
                })
            })

        })
    }

    handleCheckExistsId = (id) => {
        let {list} = this.state;
        let exists = list.filter((row) => row.id === id);
        return exists.length > 0
    }
    onDeleteMusicFromList = (baseRow) => {
        let {list} = this.state;
        this.setState({
            deleteSubmitting: `loading-${baseRow.id}`
        }, () => {
            list.map((row, index) => {
                if(row.id === baseRow.id)
                    list.splice(index, 1);

            })
            setTimeout(() => {
                this.setState({
                    list,
                    deleteSubmitting: false
                })
            }, 500)
        })

    }
    handleClearList = () => {
        this.setState({
            list: []
        })
    }
    onAddToList = (row) => {
        let {list} = this.state;
        this.setState({
            addSubmitting: `loading-${row.id}`
        }, () => {
            if(!this.handleCheckExistsId(row.id) && list.length < 20)
                list.push(row);

            setTimeout(() => {
                this.setState({
                    list,
                    addSubmitting: false
                })
            }, 500)
        })

    }
    onSortEnd = ({ oldIndex, newIndex }) => {
        this.setState({
            list: arrayMoveImmutable(this.state.list, oldIndex, newIndex)
        })
    };
    onShouldCancelStart = (e) => {
        if(["SVG", "PATH", "BUTTON"].includes(e.target.tagName.toUpperCase()))
            return true;

    }
    handlePushVote = async (e) => {
        const {applicationContext, accountContext, alert, ticketCreatingContext} = this.props;
        this.setState({ submitting: true })
        try{
            let music_videos = [];
            this.state.list.map((vote) => music_videos.push(vote.id))

            await AdminService.pushRankOfMusicVideo(applicationContext.state.topListId, {
                top_list_id: applicationContext.state.topListId,
                music_videos: music_videos
            }).then((data) => {
                this.setState({ submitted: true });
            }).finally(() => {
                this.setState({ submitting: false });
            })
        }catch (err){
            this.setState({ submitting: false });
        }

    }
    handleScroll = async (page) => {
        let {data} = this.state;
        return await AdminService.topListUserVotes({
            page,
            top_list_id: this.state.topListId,
            limit: 30,
            sort_field: 'id',
            sort_dir: 'desc'
        })
    }
    renderDate = (date) => {
        const {applicationContext} = this.props;
        return toDate(date, applicationContext.state.locale);
    }

    render() {
        const {adminContext} = this.props;
        return (
            <LoggedIn loading={this.state.loading}>
                {this.state.loading?(
                    <SpinnerContainer>
                        <Spinner animation="border" role="status" variant={"primary"}>
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </SpinnerContainer>
                ):(
                    <React.Fragment>
                        {this.state.submitted?(
                            <SubmittedSuccessfully>
                                <FontAwesomeIcon icon={"check-circle"} /><br/>
                                {("This week's top20 list has been submitted successfully.")}
                            </SubmittedSuccessfully>
                        ):(
                            <Row>
                                <Col md={9}>
                                    <Row>
                                        <DataList
                                            columns={[
                                                {key: "id", label: "#", format: "number", align: "left"},
                                                {key: "date", label: "Date Of Ingest", format: "date", align: "left"},
                                                {key: 'code', label: "Clip Code", align: "left"},
                                                {key: 'singer', label: "Artist Name", align: "left"},
                                                {key: 'name', label: "Song Title", align: "left"},
                                                {key: 'total_vote', label: "Total Votes", align: "left"},
                                                {key: 'total_score', label: "Score", align: "left"},
                                                {key: "", label: "Top20", actions: [
                                                    {label: "ADD", route: "#", onClick: this.onAddToList, color: "#4caf50", loading: this.state.addSubmitting, submitting: true, hidden: ["id", "FUNC_TRUE", this.handleCheckExistsId]},
                                                    {label: "DELETE", route: "#", onClick: this.onDeleteMusicFromList, color: "#d50f0a", loading: this.state.deleteSubmitting, submitting: true, hidden: ["id", "FUNC_NOT", this.handleCheckExistsId]},
                                                ]}
                                            ]}
                                            data={this.state.data}
                                            action={this.handleScroll}
                                            search={this.renderSearch}
                                            sort={this.state.sort}
                                            scrollLoading={true}
                                            loading={false}
                                            disableNotFound={true}
                                            footer={true}
                                        />
                                    </Row>

                                </Col>
                                <Col>
                                    <ListContainer>
                                        {this.state.list.length > 0 && <SendButton variant={"success"} disabled={this.state.submitting || this.state.list.length < 20} onClick={this.handlePushVote}>{this.state.submitting?(<Spinner animation="border" size={"sm"} role="status" variant={"success"} />):("Save ("+this.renderDate(this.state.topList.date)+")")}</SendButton>}
                                        <SortableList items={this.state.list}
                                                      helperClass={"sort-list-item"}
                                                      isVoted={false}
                                                      shouldCancelStart={this.onShouldCancelStart}
                                                      onSortEnd={this.onSortEnd}
                                                      onDeleteMusicFromList={this.onDeleteMusicFromList}
                                        />

                                    </ListContainer>
                                </Col>
                            </Row>
                        )}

                    </React.Fragment>
                )}
            </LoggedIn>
        );
    }
}

export default withAlert()(withApplicationContext(withRouter(withAdminContext(withAdminAuth(Top20Votes)))));