import styled from "styled-components";

export const Container = styled.div`
  background: var(--color-card-background);
  color: var(--color-card-text);
  border-radius: var(--color-card-border-radius);
  padding: ${(props) => props.padding??`3em;`};
  width: 100%;
  ${(props) => props.maxWidth && `max-width: ${props.maxWidth}px;`}
  ${(props) => props.center && `
        margin: 0 auto;
  `}
  ${(props) => props.top && `
        margin-top: ${props.top};
  `}
  ${(props) => props.bottom && `
        margin-bottom: ${props.bottom};
  `}
  ${(props) => props.margin && `
        margin: ${props.margin} !important;
  `}
  
`;
