import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
export const HeaderBar = styled.div`
  width: 100%;
  height: 300px;
  background-color: #000;
  overflow: hidden;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 700px) {
    height: 70px;
  }
`;
export const HeaderBarWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-image: url("/assets/images/top-20-bg.jpeg");
  background-size: cover;
  background-position: center center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: all ease-in-out .3s;
  & video {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    height: 100%;
    width: 100%;
    transition: all ease-in-out .3s;
  }
`;
export const HeaderLogo = styled.img`
  height: 80px;
  width: auto;
  position: absolute;
  left: 1em;
  top: 1em;
  border-radius: 15px;
  opacity: 0;
  transition: all ease-in-out 2s;
  @media screen and (max-width: 700px) {
    height: 50px;
  }
`;
export const HeaderMenuContainer = styled.div`
  width: auto;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  right: 1em;
  bottom: -4em;
  opacity: 0;
  transition: all linear 1s;
  z-index: 99999;
  @media screen and (max-width: 700px) {
    height: 50px;
  }
`;
export const HeaderMenuItem = styled.div`
  background: #242424;
  color: #fff;
  padding: 1em;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  margin: 0 .3em;
  cursor: pointer;
  position: relative;
  transition: all ease-in-out .3s;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & b{
    margin-left: .4em;
  }
  ${(props) => props.active && `
    background: var(--color-primary);
  `}
  ${(props) => props.noLink?`
    cursor: default;
  `:`
    &:hover{
      background: var(--color-primary);
    }
  `}
`

export const PopupText = styled.div`
  font-size: 1em;
  font-weight: 600;
  color: #fff;
  padding: 1em;
`
export const MenuItemNumber = styled.div`
  background: #d50f0a;
  color: #fff;
  border-radius: 5px;
  padding: 0.1em 0.5em;
  margin-left: 0.7em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: .9em;
  font-weight: bold;
`