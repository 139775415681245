import styled from "styled-components";
import {Form} from 'react-bootstrap';

export const Container = styled.div`
    width: 100%;
    max-width: 600px;
    margin: 4em auto;
  @media screen and (max-width: 700px) {
    display: block;
    margin: 0em auto;
  }
`