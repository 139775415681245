import React, {Component} from 'react';
import {
    HeaderBar,
    HeaderBarWrapper,
    HeaderLogo, HeaderMenuContainer, HeaderMenuItem, MenuItemNumber,
    PopupText
} from "./style.jsx";
import {Container, Row, Col} from 'react-bootstrap';
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    ROUTE_HOME, ROUTE_TOP_20
} from "../../../routes/routes";
import withRouter from "../../../contexts/withRouter";
import Button from "../Button";
import {withAccountContext} from "../../../contexts/AccountContext";
import {isRouteActive} from "../../../helpers";
import LoginForm from "../../../scenes/Login/Forms/LoginForm";
import AccountService from "../../../services/AccountService";
class AppHeader extends Component {
    refVideo = React.createRef();
    refLogo = React.createRef();
    refMenuContainer = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            menu: false
        }
    }
    onClickMenuIcon = (e) => {
        this.setState({
            menu: !this.state.menu
        })
    }
    handleOnEnded = (e) => {
        this.refVideo.style.opacity = 0;
        this.refLogo.style.opacity = 1;
        this.refMenuContainer.style.opacity = 1;
        this.refMenuContainer.style.bottom = "-5px";

    }
    handleGoto = (route) => {
        const {navigate} = this.props;
        navigate(route);
    }
    onLogin = async (e) => {
        e.preventDefault();
        const {applicationContext, accountContext} = this.props;
        if(!accountContext.state.loggedIn)
            return applicationContext.onOpenPopup("Log In", <LoginForm onCallback={this.handleLogin} />);

        await this.handleLogin();
    }
    onRegister = async (e) => {
        e.preventDefault();
        const {applicationContext, accountContext} = this.props;
        if(!accountContext.state.loggedIn)
            return applicationContext.onOpenPopup("Create new account", <LoginForm register={true} />);

    }
    handleLogin = async () => {
        const {accountContext, applicationContext} = this.props;
        await AccountService.getUserBoard().then((data) => {
            accountContext.updateUserInfo(data, applicationContext.onClosePopup);
        });
    }
    onLogout = (e) => {
        e.preventDefault();
        const {applicationContext} = this.props;
        let component = (<PopupText>{applicationContext.translator("Are you sure to logout your account?")}</PopupText>);
        let footer = (
            <React.Fragment>
                <Button color={"success"} onClick={this.handleLogout} margin={"0 .2em"}>{applicationContext.translator("Yes, I'm sure")}</Button>
                <Button color={"danger"} onClick={applicationContext.onCloseConfirmPopup} margin={"0 .2em"}>{applicationContext.translator("No")}</Button>
            </React.Fragment>

        );
        applicationContext.onOpenConfirmPopup("Log Out", component, footer)

    }
    handleLogout = (e) => {
        const {applicationContext, accountContext, navigate} = this.props;
        accountContext.handleLogout(() => {
            applicationContext.onCloseConfirmPopup();
            navigate(ROUTE_HOME);
        })
    }
    render() {
        const {applicationContext, accountContext, history} = this.props;
        return (
            <HeaderBar>
                <Container style={{ height: "100%" }}>
                    <HeaderBarWrapper>
                        <HeaderLogo src={"/assets/images/pmc-logo.png"} ref={(ref) => this.refLogo = ref}/>
                        <HeaderMenuContainer ref={(ref) => this.refMenuContainer = ref}>
                            <HeaderMenuItem active={!accountContext.state.popup && isRouteActive(ROUTE_HOME)} onClick={(e) => this.handleGoto(ROUTE_HOME)}>Vote</HeaderMenuItem>
                            <HeaderMenuItem active={!accountContext.state.popup && isRouteActive(ROUTE_TOP_20)} onClick={(e) => this.handleGoto(ROUTE_TOP_20)}>Last Week Top20</HeaderMenuItem>
                            {/*{accountContext.state.votingList.length > 0 && <HeaderMenuItem active={accountContext.state.popup} onClick={(e) => accountContext.state.popup?accountContext.onClosePopup():accountContext.onOpenPopup()}>*/}
                            {/*    Voting List*/}
                            {/*    {accountContext.state.votingList.length > 0 && <MenuItemNumber>{accountContext.state.votingList.length} / 10</MenuItemNumber>}*/}
                            {/*</HeaderMenuItem>}*/}
                            {!accountContext.state.loggedIn && <HeaderMenuItem onClick={this.onRegister}>{applicationContext.translator("Register")}</HeaderMenuItem>}
                            {!accountContext.state.loggedIn && <HeaderMenuItem onClick={this.onLogin}>{applicationContext.translator("Login")}</HeaderMenuItem>}
                            {accountContext.state.loggedIn && <HeaderMenuItem noLink={true}>{applicationContext.translator("Welcome,")} <b>{accountContext.state.userInfo['first_name']}</b></HeaderMenuItem>}
                            {accountContext.state.loggedIn && <HeaderMenuItem onClick={this.onLogout}>{applicationContext.translator("Log Out")}</HeaderMenuItem>}
                        </HeaderMenuContainer>
                        <video playsInline autoPlay muted poster="/assets/images/top-20-bg.jpeg" onEnded={this.handleOnEnded} ref={(ref) => this.refVideo = ref}>
                            <source src="/assets/videos/VID-20230510-WA0049.mp4" type="video/mp4" />
                        </video>

                    </HeaderBarWrapper>
                </Container>
            </HeaderBar>
        );
    }
}

export default withApplicationContext(withRouter(withAccountContext(AppHeader)));