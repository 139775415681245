import React, {Component} from 'react';
import {Container, Wrapper, Number, MusicVideoInfo, MusicVideoName, MusicVideoSinger, VoteButtonContainer, VoteButton, DeleteButton} from "./style.jsx";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {withAccountContext} from "../../../contexts/AccountContext";
class MusicVideo extends Component {
    onClickVote = (e) => {
        const {data, accountContext} = this.props;
        accountContext.onOpenPopup(data['id'], data);
    }
    renderButton = () => {
        const {accountContext, data} = this.props;
        if(accountContext.handleCheckExistsId(data['id']))
            return <VoteButton variant={"success"} onClick={(e) => accountContext.onDeleteMusicFromVoteList(data['id'])}><FontAwesomeIcon icon={"thumbs-down"}/> Delete from voting list</VoteButton>

        return <VoteButton variant={"vote"} onClick={this.onClickVote}><FontAwesomeIcon icon={"thumbs-up"}/> Add to voting list</VoteButton>
    }
    render() {
        const {accountContext, data, md, deleteFromVote, disableButton} = this.props;

        return (
            <Container md={md??(accountContext.state.popup?4:3)}>
                <Wrapper deleteFromVote={deleteFromVote} disableButton={disableButton}>
                    <Number deleteFromVote={deleteFromVote}>{data.hasOwnProperty('rank')?data['rank']:data['number']}</Number>
                    <MusicVideoInfo>
                        <MusicVideoName><span>{data['code']}</span> - {data['singer']}</MusicVideoName>
                        <MusicVideoSinger>{data['name']}</MusicVideoSinger>
                    </MusicVideoInfo>
                    {deleteFromVote && !accountContext.state.isVoted && !this.props.isVoted && <DeleteButton variant={"danger"} onClick={(e) => accountContext.onDeleteMusicFromVoteList(data['id'])}><FontAwesomeIcon icon={"trash"}/></DeleteButton>}
                </Wrapper>
                {(!deleteFromVote && !disableButton) && <VoteButtonContainer>
                    {this.renderButton()}
                </VoteButtonContainer>}
            </Container>
        );
    }
}
MusicVideo.propTypes = {
    data: PropTypes.object,
    md: PropTypes.number,
    deleteFromVote: PropTypes.bool
}
export default withApplicationContext(withAccountContext(MusicVideo));