import React, {Component} from 'react';
import {withApplicationContext} from "../../contexts/ApplicationContext";
import {AdminProvider} from "../../contexts/AdminContext";
import {Container} from "./style";
import Loading from "../../components/ui/Loading";
import AdminTokenService from "../../services/AdminTokenService";
import AdminService from "../../services/AdminService";


class BaseMaster extends Component {
    timerCounter = null
    constructor(props) {
        super(props);
        this.state = {
            loggedIn: false,
            userInfo: {},
            loading: true,
            popup: false,
            menu: "opened",
            counter: {
                waitingForApprovalDeposits: 0
            }
        }
    }
    componentDidMount = async () => {
        const {applicationContext} = this.props;
        document.body.classList.remove("light-theme")
        document.body.classList.add('admin-theme');
        let userInfo = await this.fetchUserInfo();
        console.log(applicationContext.state)
        applicationContext.doUpdateLocale('en', () => {
            this.setState({
                userInfo,
                loggedIn: (userInfo)?true:false,
                loading: false
            })
        })
    }
    fetchUserInfo = async () => {
        let userInfo = null;
        if(AdminTokenService.getLocalAccessToken()){
            userInfo = await AdminService.getUserBoard().then((data) => {
                return data;
            })
        }
        return userInfo;
    }
    updateUserInfo = (data, callback, loggedIn = true) => {
        this.setState({
            loggedIn: loggedIn,
            userInfo: data
        }, callback)
    }

    handleLogout = async (callback) => {
        AdminTokenService.removeUser();
        this.setState({
            loggedIn: false,
            userInfo: {},
        }, callback)
    }

    handleChangeMenu = (status) => {
        this.setState({
            menu: status
        })
    }

    render() {
        const {applicationContext, adminContext, children} = this.props;
        return (
            <AdminProvider value={{
                state: this.state,
                updateUserInfo: this.updateUserInfo,
                handleLogout: this.handleLogout,
                handleChangeMenu: this.handleChangeMenu
            }}>
                {this.state.loading?(
                    <Loading animation={true} />
                ):(
                    <Container>
                        {children}
                    </Container>
                )}
            </AdminProvider>
        );
    }
}

export default withApplicationContext((BaseMaster));